export const clientEvents = {
    SHOW_SETTINGS: "showSettings",
    SHOW_NOTIFICATIONS: "showNotifications",
    ALERT_EVENT: "alertEvent",
    INITIAL_LOADING_FINISHED: 'initialLoadingFinished',
    BREADCRUMBS_LOADING_FINISHED: 'breadCrumbsLoadingFinished',
    SUB_OBJECTIVE_LOADING_FINISHED: 'subObjectiveLoadingFinished',
    RELOAD_OBJECTIVE_DASHBOARD: "reloadObjectiveDashboard",
    RELOAD_TIME_LOGS: "reloadTimeLogs",
    CLICKED_FORM_BACKGROUND: "clickedFormBackground",
    INVITES_LOADING_FINISHED: "invitesLoadingFinished"
}