import store from "@/stores/Store";
import invites from "@/stores/modules/invites";

export default {
    namespaced: true,
    state: {
        invites: [],
        newInvitesCount: 0,
    },
    mutations: {
        setInvites(state, payload) {
            state.invites = payload
        },
        setNewInvitesCount(state, payload) {
            state.newInvitesCount = payload
        },
        addInvite(state, payload) {
            state.invites.push(payload)
        }
    },
}