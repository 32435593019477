import APIEndpoints from "@/Scripts/API/Services/APIEndpoints";
import EventBus from "@/Events/EventBus";
import {clientEvents} from "@/Events/clientEventEnums";
import {okr_frontend_url} from "@/Utils";

export function signUpStudio(studioName, userName, email, password) {
    APIEndpoints.createStudio(studioName, userName, email, password).then(response => {
        if (response.status === 200) {
            EventBus.emit('authEvent', response.data['message'])
        }
    }).catch(err => {
        EventBus.emit('authEvent', err.response.data['message'])
    })

}

export function signIntoStudio(token, data) {
    APIEndpoints.signIntoStudio(token, data).then(response => {
        if (response.status === 200) {
           window.location.href = `${okr_frontend_url}/authenticate/${token}`

        }
    }).catch(err => {
        EventBus.emit(clientEvents.ALERT_EVENT, err.response.data)
    })

}

export function getStudioRoles(token, studioID, callBack) {
    APIEndpoints.getStudioRoles(token, studioID).then(response => {
        callBack(response.data)
    }).catch(err => console.log('something went wrong'))
}

export function getColleaguePermissions(token, studioID, callBack) {
    APIEndpoints.getColleaguePermissions(token, studioID).then(response => {
        callBack(response.data)
    }).catch(err => console.log('something went wrong'))
}

export function getCustomerPermissions(token, studioID, callBack) {
    APIEndpoints.getCustomerPermissions(token, studioID).then(response => {
        callBack(response.data)
    }).catch(err => console.log('something went wrong'))
}

export function getStudioTags(token, studioID, callBack) {
    APIEndpoints.getStudioTags(token, studioID).then(response => {
        callBack(response.data)
    }).catch(err => console.log('something went wrong'))
}

export function getContractTypes(token, studioID, callBack) {
    APIEndpoints.getContractTypes(token, studioID).then(response => {
        callBack(response.data)
    }).catch(err => console.log('something went wrong'))
}

export function getGeneralTimeLogs(token, studioID, callBack) {
    APIEndpoints.getGeneralTimeLogs(token, studioID).then(response => {
        callBack(response)
    }).catch(err => console.log('something went wrong'))
}


export function denyInvite(token, inviteId, studioId, callBack) {
    APIEndpoints.denyInvite(token, inviteId, studioId).then(response => {
        callBack(response.data)
    }).catch(err => console.log('something went wrong'))
}

export function acceptInvite(token, inviteId, callBack) {
    APIEndpoints.acceptInvite(token, inviteId).then(response => {
        callBack(response.data)
    }).catch(err => console.log('something went wrong'))
}