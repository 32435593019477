<template>
  <div id="profile-wrapper">
    <profile-header-component/>
  </div>
</template>

<script>
import ProfileHeaderComponent from "@/components/profile/profileHeaderComponent";
export default {
  name: "profileComponent",
  components: {ProfileHeaderComponent}
}
</script>

<style scoped>

 #profile-wrapper {
   width: 100vw;
   top: 80px;
   bottom: 0;
   position: fixed;
   height: auto;
   display: flex;
   flex-direction: column;
 }

 @media(min-width: 750px) {
   #profile-wrapper {
     width: calc(100vw - 200px);
     height: 100vh;
     left: 200px;
     top: 0;
     position: fixed;
   }
 }

</style>