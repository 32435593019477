import {createRouter, createWebHistory} from "vue-router";
import AuthComponent from "@/components/auth/authComponent";
import LoginForm from "@/components/auth/loginForm";
import SignUpForm from "@/components/auth/signUpForm";
import AppComponent from "@/components/app/appComponent";
import StudiosComponent from "@/components/studios/studiosComponent";
import InvitesComponent from "@/components/invites/invitesComponent";
import ProfileComponent from "@/components/profile/profileComponent";
import forgotPwForm from "@/components/auth/forgotPwForm";
import FeedbackComponent from "@/components/feedback/feedbackComponent";


const routes = [
    {
        name: "Authentication",
        path: '/',
        alias: '/login',
        redirect: { name: 'Login' },
        component: AuthComponent,
        children:  [
            {
                name: "Login",
                path: '/',
                alias: 'login',
                component: LoginForm
            },
            {
                name: "SignUp",
                path: '/sign-up',
                component: SignUpForm
            },
            {
                name: "ForgotPw",
                path: '/forgot-pw',
                component: forgotPwForm
            }
        ]
    },
    {
        name: "Application",
        path: '/app',
        alias: '/',
        component: AppComponent,
        redirect: {name: 'Studios'},

        children:  [
            {
                name: "Studios",
                path: '/studios',
                component: StudiosComponent
            },
            {
                name: "Invites",
                path: '/invites',
                component: InvitesComponent
            },
            {
                name: "Profile",
                path: '/profile',
                component: ProfileComponent
            },
            {
                name: "Feedback",
                path: '/feedback',
                component: FeedbackComponent
            }
        ]
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    base: '/dashboard/',
    routes
})

export default router

export function getRoute(routeName){
    for (const route of routes) {
        if (route.name == routeName) {
            return route
        } else if (route.children){
            for (const childRoute of route.children) {
                if (childRoute.name == routeName){
                    return childRoute
                }
            }
        }
    }
    throw `No such route: ${routeName}`
}


export function routeTo(destination) {
    const destinationRoute = getRoute(destination)
    if (destinationRoute) {
        router.push(destinationRoute).catch(err => console.log(err))
    }
}

export function routeToObjectiveDetail(id) {
    const destinationRoute = getRoute("detail")
    router.push({name: destinationRoute.name, params: {id: id}})
        .catch(err => console.error(err))
}

export function routeToProfile(userId) {

    const destinationRoute = getRoute("profile")
    router.push({name: destinationRoute.name, params: {id: userId}})
        .catch(err => console.error(err))
}