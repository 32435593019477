<template>
  <div>
    <form id="login-form">
      <div id="login-top-bar">
        <h1 class="text biggest darkmode font-weight-normal">Forgot password</h1>
      </div>
      <div id="login-sub-top-bar">
        <h1 class="text big darkmode font-weight-normal">Please type in your email!</h1>
      </div>
      <div id="login-content">
        <div class="login-row">
          <h3 class="text default darkmode font-weight-normal">Email</h3>
          <input type="email" class="form-input" v-model="email">
        </div>

        <button class="auth-submit-button" @click.prevent="onSubmitClick">Send email</button>
        <p class="text small darkmode" id="button-devider">Or</p>
        <button class="auth-signup-button">
          <router-link to="/">
            Sign in
          </router-link>
        </button>
        <p class="text small darkmode" id="button-devider">Or</p>
        <button class="auth-signup-button">
          <router-link to="/sign-up">
            Create a new account
          </router-link>
        </button>
      </div>

    </form>
  </div>
</template>

<script>
import {signUpStudio} from "@/Scripts/API/StudioAPI";
import EventBus from "@/Events/EventBus";
import {clientEvents} from "@/Events/clientEventEnums";
import {signUp} from "@/Scripts/API/AuthAPI";
import APIEndpoints from "@/Scripts/API/Services/APIEndpoints";
import {performRequestWithoutToken, performRequestWithQuery} from "@/Scripts/API/Services/APIService";
import {alertTypes} from "@/Enums";

export default {
  name: "signUpForm",
  data() {
    return {
      email: '',
    }
  },
  mounted() {
    document.title = "Sign up - OKR System"
  },
  methods: {
    onSubmitClick() {
      if (this.email !== '') {
        performRequestWithoutToken(APIEndpoints.forgotPassword, this.email).then(() => {
          EventBus.emit(clientEvents.ALERT_EVENT,  'We send you an email with details for your new password!', alertTypes.INFORMATION)
        })
      }
      else {
        EventBus.emit(clientEvents.ALERT_EVENT,  'Password and Re Password need to match!', alertTypes.INFORMATION)
      }
    }
  }
}
</script>

<style scoped>
@import "@/styles/TextStyle.css";
@import "@/styles/PagesStyles/loginStyle.css";

a {
  color: var(--highlight-color-primary) !important;
}

</style>