<template>
  <div class="form-row">

    <div class="form-row-information">
      <label class="text default font-weight-normal">{{title}}</label>
      <info-button v-if="hasInfoButton" @buttonAction="infoButtonOptions.buttonAction" :has-button="infoButtonOptions.hasButton" :button-text="infoButtonOptions.buttonText" :is-warning="infoButtonOptions.isWarning" :texts="infoButtonOptions.texts"/>
    </div>

    <div class="form-row-input">

      <input :name="type" :ref="type" type="text" v-if="type === 'text'" v-model="selectedValue" :placeholder="placeHolder" class="input-element default text-element" :disabled="isDisabled">
      <input :name="type" :ref="type" type="email" v-else-if="type === 'email'" v-model="selectedValue" :placeholder="placeHolder" class="input-element default text-element" :disabled="isDisabled">
      <input :name="type" :ref="type"  type="date" v-else-if="type === 'date'" v-model="selectedValue" class="input-element default font-weight-normal date-element" :disabled="isDisabled">
      <input :name="type" :ref="type" type="number" v-else-if="type === 'number'" v-model="selectedValue" :placeholder="placeHolder" class="input-element default text-element" :disabled="isDisabled">
      <input :name="type" :ref="type" type="password" v-else-if="type === 'password'" v-model="selectedValue" :placeholder="placeHolder" class="input-element default text-element" :disabled="isDisabled">
      <input :name="type" :ref="type" type="checkbox" v-else-if="type === 'checkbox'" v-model="selectedValue" class="form-checkbox" :disabled="isDisabled">
      <div v-else-if="type === 'range'" class="range-wrapper">
        <div class="range-value-wrapper">
          <p class="text default font-weight-normal fit-to-container">{{selectedValue / rangeOptions.devider}}</p>
        </div>
        <input :name="type" type="range" v-model="selectedValue" :max="rangeOptions.max" :min="rangeOptions.min">
      </div>
      <select :name="type" v-else-if="type === 'select'" class="input-element default date-element" v-model="selectedOption" @change="onInput">
        <option v-for="(option, index) in selectOptions" :key="index" :value="option">{{option[valueField]}}</option>
      </select>
      <textarea :name="type" :ref="type" class="input-element text-area default" v-else-if="type === 'textarea'" @input="resizeTextarea"  v-model="selectedValue" :placeholder="placeHolder"></textarea>
      <custom-multiselect v-else-if="type === 'multiSelect'" :should-close-drop-down="shouldCloseDropDowns" :is-editable="multiselectOptions.isEditable" :options="multiselectOptions.options" :is-in-sections="multiselectOptions.isInSections" :value-field="multiselectOptions.valueField" v-model="selectedMultiselectOptions" :selected-options="preSelectedMultiselectOptions"/>
      <div class="image-wrapper img"  v-else-if="type === 'image'">
        <div class="image-preview-wrapper img" :class="{'img-too-big': imageSizeMb > maxImageUploadSize}" v-if="image">
          <img :src="imageUrl" class="preview-image img">
          <div class="image-info-wrapper img">
            <p class="text smaller fit-to-container image-info img">{{imageSizeMb}}Mb / {{maxImageUploadSize}}Mb</p>
            <!-- <p class="text smaller fit-to-container image-info" :class="{'img-too-big': imageSizeMb > maxImageUploadSize}">{{imageName}}</p>
            <p class="text smaller fit-to-container image-info">{{imageSizeMb}} / {{maxImageUploadSize}}</p>-->
          </div>
          <span class="material-symbols-outlined close-icon img" @click.prevent="removeImagePreview">close</span>
        </div>
        <button class="image-select-button img" @click.prevent="openImagePicker">

          <p class="text default button-text img">{{imageButtonText}}</p>
        </button>
        <input
            class="img"
            type="file"
            ref="fileInput"
            style="display: none;"
            accept="image/*"
            @change="onImageInputChange"
        />
      </div>
      <slot name="customRowInput" v-else-if="type === 'custom'"></slot>

    </div>
  </div>
</template>

<script>
import CustomMultiselect from "@/components/miniComponents/UIComponents/CustomMultiselect";
import {maxImageUploadSize} from "@/Utils";
import {watch} from 'vue';
import InfoButton from "@/components/miniComponents/UIComponents/infoButton";
export default {
  name: "formRow",
  components: {CustomMultiselect, InfoButton},
  emits: ['update:modelValue', 'removeRow'],
  props: {
    hasInfoButton: {},
    infoButtonOptions: {},
    hasDeleteButton: {
      default: false
    },
    isDisabled: {},
    title: {},
    preselectedValue: {},
    type: {},
    selectOptions: {},
    valueField: {},
    preSelectedOption: {},
    multiselectOptions: {},
    rangeOptions: {},
    hasFocus: {},
    placeHolder: {}

  },
  computed: {
    imageUrl: function () {
      return URL.createObjectURL(this.image)
    },
    imageSizeMb: function () {
      if (!this.image) return 0
      return (this.image.size / (1024 * 1024)).toFixed(1); // auf 2 Dezimalstellen gerundet
    },
    imageButtonText: function () {
      if (!this.image) return '+ Add image'
      else return '+ Change image'
    }
  },
  data() {
    return {
      baseInputTypes: ['email', 'password', 'text', 'number', 'search'],
      selectedOption: this.preSelectedOption,
      preSelectedMultiselectOptions: [],

      selectedMultiselectOptions: [],
      shouldCloseDropDowns: false,
      selectedValue: this.preselectedValue,
      imageName: '',
      image: null,
      maxImageUploadSize
    }
  },
  mounted() {
    console.log(this.type)
    if(this.hasFocus) {
      const type = this.type
      //const selector = `input[name=${type}]`
      const input =  this.$refs[type]//document.querySelector(selector)
      input.focus()
    }
    if (this.type === 'multiSelect') {
      this.preSelectedMultiselectOptions = this.multiselectOptions.preSelectedMultiSelectOptions
    }
    watch(() => this.multiselectOptions, (newOption, oldOption) => {
      this.preSelectedMultiselectOptions = newOption.preSelectedMultiSelectOptions
    })

    watch(() => this.preselectedValue, (newOption, oldOption) => {
      this.selectedValue = newOption

    })
  },
  methods: {

    closeDropDowns(event) {
      const validClasses = ['form-card', 'form-container-top', 'form-container-content', 'form-container-bottom', 'form-content-row'];
      if (validClasses.some(className => event.target.classList.contains(className))) {
        this.shouldCloseDropDowns = !this.shouldCloseDropDowns
      }
    },
    resizeTextarea(e) {
      let textarea = this.$refs[this.type]; // Der referenzierte Textbereich

      // Prüfe, ob der Text Zeilenumbrüche enthält
      const text = textarea.value; // Der Inhalt des Textbereichs
      const hasLineBreak = text.includes('\n'); // Überprüfe auf Zeilenumbrüche

      if (hasLineBreak) {
        // Setze die Höhe auf 'auto', um die natürliche Höhe zu erhalten
        textarea.style.height = "auto";

        // Danach auf den aktuellen Scroll-Höhe einstellen, um Platz für den Inhalt zu schaffen
        textarea.style.height = (textarea.scrollHeight - 20) + "px"; // Anpassung um Überschüsse zu vermeiden
      } else {
        textarea.style.height = "20px";
      }
    },
    onImageInputChange(e) {
      const files = e.target.files || e.dataTransfer.files;
      if (!files) return;
      let image = files[0];
      this.imageName = image.name
      this.image = image
    },
    removeImagePreview() {
      this.image = null
      this.imageName = ''
    },
    openImagePicker() {
      this.$refs.fileInput.click();  // Öffnet den Dateiauswahl-Dialog

    }

  },
  watch: {
    image(newOption, oldOption) {
      this.$emit('update:modelValue', newOption);
    },
    selectedValue(newOption, oldOption) {
      this.$emit('update:modelValue', newOption);
    },
    preSelectedOption(newOption, oldOption) {
      this.selectedOption = newOption
    },
    selectedMultiselectOptions(newOptions, oldOptions) {
      this.$emit('update:modelValue', newOptions);

    },
    selectedOption(newOption, oldOption) {
      // Wenn sich der ausgewählte Wert ändert, emitte ein neues Event
      if (newOption !== oldOption) {
        this.$emit('update:modelValue', newOption);
      }
    }
  }

}
</script>

<style scoped>
@import "@/styles/FormStyle.css";
</style>