export default {
    namespaced: true,
    state: {
        newNotifications: 0,
        notifications: []
    },
    mutations: {
        increaseNewNotifications(state, payload) {
            state.newNotifications += payload
        },
        setNewNotificationsCount(state, payload) {
            console.log(payload)
            state.newNotifications = payload
        },
        setNotifications(state, payload) {
            state.notifications = payload
        },
        resetNewNotifications(state) {
            state.newNotifications = 0
        }
    },
}