<template>
  <div id="select" @click="$emit('buttonAction')">
    <span id="icon" class="material-symbols-outlined">add</span>
    <button id="button">{{title}}</button>
  </div>
</template>

<script>
export default {
  name: "AddButton",
  emits: ['buttonAction'],
  props: {
    title: String
  },

}
</script>

<style scoped>

#select {

  /* padding: 0 .5em;*/
  cursor:pointer;
  font-size: var(--button-font-size-big);
}

#button{
  font-size: var(--button-font-size-big);
  color: var(--highlight-color-primary);
  background: none;
  border: none;
  text-align: left;
  width: fit-content;
  cursor: pointer;
  padding: 0;
}

select::-ms-expand {
  display: none;
}
#select {
  margin-left: 10px;
  display: flex;
  height: 40px;
  line-height: 3;
  border-radius: 15px;
  width: fit-content;
  cursor: pointer;
}

#select:hover {
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -ms-transform: scale(1.1);
  -o-transform: scale(1.1);
  transform: scale(1.1);
}

#icon {
  display: flex;
  align-items: center;
}


.material-symbols-outlined {
  font-size: 30px;

  color: var(--highlight-color-primary);
  font-variation-settings:
      'FILL' 0,
      'wght' 400,
      'GRAD' 0,
      'opsz' 48
}
</style>