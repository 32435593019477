<template>
  <div id="header-wrapper">
    <img alt="" id="header-banner" src="/3D_würfel.jpg"/>
    <div id="header-edit-wrapper">
      <span class="material-symbols-outlined edit-icon">edit</span>
    </div>
  </div>
  <div id="picture-wrapper">
    <div id="profile-picture-wrapper">
      <img id="profile-picture" alt="" :src="baseMediaUrl + this.user['image_url']">
      <div id="profile-picture-edit-wrapper">
        <span class="material-symbols-outlined edit-icon" @click="toggleShowChangeProfilePictureForm">edit</span>
      </div>
    </div>
  </div>
  <add-profile-picture-form v-if="showChangeProfilePictureForm" @uploadProfilePicture="uploadProfilePicture" @closeForm="toggleShowChangeProfilePictureForm"/>
</template>

<script>
import {baseMediaUrl, convertImage} from "@/Utils";
import {userMixin} from "@/mixins/userMixin";
import AddProfilePictureForm from "@/components/profile/forms/addProfilePictureForm";
import APIEndpoints from "@/Scripts/API/Services/APIEndpoints";
import {uploadImage} from "@/Scripts/API/Services/APIService";

export default {
  name: "profileHeaderComponent",
  components: {AddProfilePictureForm},
  mixins: [userMixin],
  data() {
    return {
      baseMediaUrl,
      showChangeProfilePictureForm: false
    }
  },
  methods: {
    toggleShowChangeProfilePictureForm() {
      this.showChangeProfilePictureForm = !this.showChangeProfilePictureForm
    },
    uploadProfilePicture(image) {
      this.toggleShowChangeProfilePictureForm()
      let formData = convertImage(image)
      uploadImage(APIEndpoints.uploadProfilePicture, formData). then(response => {
        console.log(response)
        this.setUser(response)
        console.log('user', this.user)
      })
    }
  }
}
</script>

<style scoped>

  #header-wrapper {
    width: 100%;
    height: 120px;
    position: relative;
  }

  #header-banner {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 5;
    object-fit: cover;
  }

  #profile-picture {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
  }

  #profile-picture-edit-wrapper {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 16;
    position: absolute;
    border-radius: 100%;
    display: none;
    cursor: pointer;
  }

  #profile-picture-wrapper:hover > #profile-picture-edit-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  #header-wrapper:hover > #header-edit-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .edit-icon {
    font-size: 32px;
    color: #eaeaea;
  }

  #header-edit-wrapper {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: none;
    position: absolute;
    z-index: 10;
    cursor: pointer;

  }

  #picture-wrapper {
    width: 100%;
    height: 100px;
    top: 65px;
    position: absolute;
    display: flex;
    justify-content: center;
    z-index: 15;
  }

  #profile-picture-wrapper {
    height: 100%;
    aspect-ratio: 1/1;
    object-fit: cover;
    border-radius: 100%;
    position: relative;
  }

  @media (min-width: 750px) {
    #profile-picture-wrapper {
      justify-content: flex-start;
    }

    #profile-picture-wrapper {
      justify-content: flex-start;
    }

    #profile-picture-wrapper {
      margin-left: 20px;
      margin-right: auto;
    }

  }
</style>