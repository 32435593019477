import { createStore } from 'vuex';
import theme from "@/stores/modules/theme";
import studio from "@/stores/modules/studio";
import user from "@/stores/modules/user";
import alerts from "@/stores/modules/alerts";
import notifications from "@/stores/modules/notifications";
import invites from "@/stores/modules/invites";


const store = createStore({
    modules: {
        theme,
        studio,
        user,
        alerts,
        notifications,
        invites
    }
})

export default store

