<template>
  <section id="app-content">
    <pop-up-alert-component ref="alert-component"/>
    <menu-component/>
    <settings-component v-if="showSettings"/>
    <loading-component v-if="isLoading"/>
    <socket-component/>
    <router-view/>
  </section>
</template>

<script>
import { mapMutations, mapState,} from 'vuex';
import LoadingComponent from "@/components/miniComponents/UIComponents/LoadingComponent";
import MenuComponent from "@/components/SideBar/sideBarComponent";
import EventBus from "@/Events/EventBus";
import {getOwnUserData} from "@/Scripts/API/UserAPI";
import {routeTo} from "@/Router";
import SettingsComponent from "@/components/Settings/settingsComponent";
import {clientEvents} from "@/Events/clientEventEnums";
import {themeMixin} from "@/mixins/themeMixin";
import APIEndpoints from "@/Scripts/API/Services/APIEndpoints";
import {performRequest, performPostRequest} from "@/Scripts/API/Services/APIService";
import {userMixin} from "@/mixins/userMixin";
import SocketComponent from "@/logicComponents/socketComponent";
import {invitesMixin} from "@/mixins/invitesMixin";
import {notificationsMixin} from "@/mixins/notificationsMixin";
import PopUpAlertComponent from "@/components/miniComponents/alerts/popUpAlertComponent";
export default {
  name: "appComponent",
  components: {
    PopUpAlertComponent,
    SocketComponent, SettingsComponent, MenuComponent, LoadingComponent},
  computed: {
    ...mapState({
      isLoading: state => state.studio.isLoading,
      tags: state => state.tags.tags,
      objectives: state => state.objectives.objectives,
      isDarkmode: state => state.theme.isDarkmode,
      studio: state => state.studio.studio,
      roles: state => state.roles.roles,
      ownUser: state => state.user.user,
      isCustomer: state => state.user.isCustomer,
      users: state => state.users.users,
      lastScreenVisit: state => state.user.visitedScreensLast,
    }),
  },

  mixins: [themeMixin, userMixin, invitesMixin, notificationsMixin],
  data() {
    return {
      showSettings: false,
      alertComponent: null
    }
  },
  mounted() {
    EventBus.on(clientEvents.SHOW_SETTINGS, () => {
      this.showSettings = !this.showSettings
    })
    this.checkTheme()
    this.saveTheme(localStorage.getItem('theme'))

    performRequest(APIEndpoints.getScreenVisits).then(data => {
      data['app_visited_last'] = new Date().toISOString()
      this.setVisitedScreens(data)

      performRequest(APIEndpoints.getOwnUserStudioInvites).then(invites => {
        this.setInvites(invites)

        console.log('invites', invites)
      })

      performPostRequest(APIEndpoints.setScreenVisits, data).then(() => {
        performRequest(APIEndpoints.getNotificationAmount).then(data => {
          console.log(data['notifications'])
          this.setNewInvitesCount(data['invites'])
          this.setNewNotificationsCount(data['notifications'])
          EventBus.emit(clientEvents.INVITES_LOADING_FINISHED)
        })
      })
    })

    this.init()

    EventBus.on(clientEvents.INITIAL_LOADING_FINISHED, () => {

    })
  },
  methods: {
    ...mapMutations('studio', ['setStudio', 'setConnection', 'setIsLoading', 'setContractTypes', "setFeedbackTypes"]),
    ...mapMutations('user', ['setUser', "setVisitedScreens"]),
    ...mapMutations('theme', ['setDarkmode', 'toggleDarkmode']),
    ...mapMutations('notifications', ['increaseNewNotifications']),

    init() {
      if (this.$cookies.get('authToken') === null) {
        this.$cookies.remove('authToken')
        routeTo('Authentication')
      } else {
        this.setIsLoading(true)
        getOwnUserData(this.$cookies.get("authToken"), (response) => {
          this.setUser(response)
          this.setIsLoading(false)
        })
      }
    },
    countNewNotifications(days, lastScreenVisit) {
      let counter = 0
      let lastVisit = new Date(lastScreenVisit)
      for (let i = 0; i < days.length; i++) {
        let day = days[i]
        for (let y = 0; y < day.notifications.length; y++) {
          let notificationDate = new Date(day.notifications[y]['timestamp'])
          if (notificationDate.getTime() > lastVisit.getTime()) {
            counter++
          }
        }
      }
      return counter
    },

  }
}
</script>

<style scoped>
#app-content {
  width: 100vw;
  height: fit-content;
  max-height: 100vh;
}

.slide-enter-active {
  transition: transform 0.15s ease-out;
}
.slide-enter-from {
  transform: translateX(100%);
}
.slide-enter-to {
  transform: translateX(0);
}

.slide-item {
  position: absolute;
  right: -100%; /* Positioniert das Element außerhalb des Bildschirms */
  /* Fügen Sie hier zusätzliche Stile hinzu */
}
</style>