<template>
  <div id="alert-slider">
    <transition name="alert-slide">

      <div class="alert" v-if="show">
        <span class="material-symbols-outlined status-icon"
              :class="{success: alertType === alertTypes.SUCCESS, warning: alertType === alertTypes.WARNING}">{{ iconName }}</span>

        <p class="text default">{{ message }}</p>
        <span class="material-symbols-outlined close-icon" @click="discardNotification">close</span>
      </div>
    </transition>
  </div>
</template>

<script>
import {alertTypes} from "@/Enums";
import {clientEvents} from "@/Events/clientEventEnums";
import EventBus from "@/Events/EventBus";

export default {
  name: "popUpAlertComponent",
  data() {
    return {
      show: false,
      message: 'Work in progress',
      alertTypes,
      alertType: alertTypes.SUCCESS
    }
  },
  computed: {
    iconName: function () {
      if (this.alertType === alertTypes.SUCCESS) {
        return 'check'
      } else if (this.alertType === alertTypes.INFORMATION) {
        return 'info'
      } else {
        return 'warning'
      }
    }
  },
  mounted() {
    EventBus.on(clientEvents.ALERT_EVENT, (message, type) => {
      this.showAlert(message, type)
    })
  },
  methods: {
    showAlert(message, type) {
      console.log(message, type)
      this.message = message;
      this.show = true;
      this.alertType = type

      setTimeout(() => {
        this.show = false;
      }, 60000);
    },
    discardNotification() {
      this.show = false
    }
  }
}
</script>

<style scoped>

#alert-slider {
  width: 100%;
  height: 0;
  top: 0;
  display: flex;
  justify-content: center;
  position: fixed;
  z-index: 1000;
}

.alert {

  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  column-gap: 20px;
  top: 0;
  gap: 10px;
  overflow: hidden;
  /*
  background-color: rgba(32, 32, 37, 0.2);
   */
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  box-shadow: 0px 12px 22px rgba(0, 0, 0, 0.15);
  text-align: center;
  transition: transform 0.5s;

  padding: 20px;
  font-size: 16px;
  width: fit-content;
  height: fit-content;
}

.text {
  white-space: nowrap;
}

.status-icon {
  color: var(--highlight-color-primary);
}

.status-icon.success {
  color: var(--color-success);
}

.status-icon.info {
}

.status-icon.warning {
  color: var(--color-warning);

}

.alert-slide-enter-active {
  animation: slide-in 0.5s;
}

.alert-slide-leave-active {
  animation: slide-out 0.5s;
}

@keyframes slide-in {
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0);
  }
}

@keyframes slide-out {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-100%);
  }
}

@media (min-width: 750px) {
  #alert-slider {
    width: calc(100% - 200px);
    left: 200px;
  }
}

</style>