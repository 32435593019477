<template>
  <section id="menu">
    <div id="menu-top-bar" class="default">
      <span  @click="toggleMenu" v-if="!isMobileMenuOpen" class="material-symbols-outlined mobile-menu-icon icon">menu</span>
      <span  @click="toggleMenu" v-if="isMobileMenuOpen" class="material-symbols-outlined mobile-menu-icon icon">close</span>

      <span id="mobile-greetings-text" class="text darkmode">Hello {{user.username}} {{possibleEmojis[randInt]}}</span>
      <img :src="require(`@/assets/StudioMerkas_Logo.png`)" id="mobile-merkas-logo">
    </div>
    <div id="menu-section" :class="{open: isMobileMenuOpen}">
      <div id="menu-content">
        <div>
          <img :src="require(`@/assets/StudioMerkas_Logo.png`)" id="desktop-merkas-logo">
          <p id="desktop-studio-indicator" class="text darkmode font-weight-normal">STUDIO MERKAS</p>
        </div>
        <div id="items-wrapper">
          <div id="main-items">
            <!--
            <span id="desktop-greetings-text" class="text darkmode">Hello {{user.username}} {{possibleEmojis[randInt]}}</span>
            --->
            <span id="desktop-greetings-text" class="text darkmode">Hello {{user.username}}</span>

            <ul id="nav-list" class="default">
              <li><router-link to="/studios" class="text font-weight-normal">Studios</router-link></li>
              <div class="nav-entry-wrapper">
                <li><router-link to="/invites" class="text font-weight-normal">Invites</router-link></li>
                <div class="new-notification-wrapper" v-if="newInvitesCount >= 1">
                  <p class="text default" id="invites-count">{{newInvitesCount}}</p>
                </div>
              </div>
              <li><router-link to="/feedback" class="text font-weight-normal">Feedback</router-link></li>
            </ul>
          </div>
          <div id="secondary-items">
            <li class="secondary-row" @click="clickShowSettings">
              <span class="material-symbols-outlined secondary-row-item icon darkmode">settings</span>
              <p class="secondary-row-item text darkmode font-weight-normal">Settings</p>
            </li>
            <li class="secondary-row">
              <span @click="clickSignOut" class="material-symbols-outlined secondary-row-item icon darkmode">logout</span>
              <p @click="clickSignOut" class="secondary-row-item text darkmode font-weight-normal">Sign out</p>
            </li>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import {getRandomInt} from "@/Utils";
import {mapMutations, mapState} from "vuex";
import EventBus from "@/Events/EventBus";
import {clientEvents} from "@/Events/clientEventEnums";
import {userMixin} from "@/mixins/userMixin";
import {invitesMixin} from "@/mixins/invitesMixin";

export default {
  name: "menuComponent",
  mixins: [userMixin, invitesMixin],
  data() {
    return {
      possibleEmojis: ['😄', '😀', '🥹', '😎', '🤩', '🥳'],
      randInt: 0,
      isMobileMenuOpen: false,
      toggleDarkmode: this.toggleDarkmode
    }
  },
  mounted() {
    this.randInt = getRandomInt(this.possibleEmojis.length)
  },
  methods: {
    ...mapMutations("theme", ["toggleDarkmode"]),

    toggleMenu() {
      this.isMobileMenuOpen = !this.isMobileMenuOpen
    },
    clickSignOut() {
      //signOut(this.$cookies.get('authToken'), () => {})
      this.$cookies.remove('authToken')
      location.reload()
    },
    clickShowSettings() {
      EventBus.emit(clientEvents.SHOW_SETTINGS)
    }
  }
}
</script>

<style scoped>
@import "@/styles/PagesStyles/menuStyle.css";
@import "@/styles/iconStyle.css";
@import "@/styles/TextStyle.css";

.icon {
  margin-left: 0;
}

.nav-entry-wrapper {
  position: relative;
}

.new-notification-wrapper {
  width: 30px;
  height: 30px;
  position: absolute;
  background-color: red;
  right: -33px;
  bottom: 5px;
  top: auto;
  left: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

#invites-count {
  color: white;
}

.new-notification-indictor {

}

</style>