<template>
  <div id="wrapper">
    <span class="text-information-medium" id="value">{{value[valueField]}}</span>
    <span id="icon" class="material-symbols-outlined" v-if="canDeleteTag" @click="$emit('remove', value['id'])">close</span>
  </div>
</template>

<script>
import watch from 'vue';
export default {
  name: "TagComponent",
  props: {
    value: Object,
    valueField: {
      default: "value"
    },
    canDeleteTag: {
      default: true
    }
  },
  data() {
    return {
    }
  },
  mounted() {
  }
}
</script>

<style scoped>
  #wrapper {
    width: fit-content;
    min-height: 30px;
    max-height: 30px;
    background-color: var(--highlight-color-primary);
    margin-left: 5px;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  #value {
    margin-left: 8px;
    color: #eaeaea;
    margin-right: 5px;
    font-weight: normal;
  }

  #icon {
    color: #eaeaea;
    margin-right: 5px;
    font-size: 18px;
    cursor: pointer;
  }

</style>