import {signOut} from "@/Scripts/API/AuthAPI";
import store from "@/stores/Store";

export default {
    namespaced: true,
    state: {
        studio: {},
        contractTypes: [],
        socketConnection: null,
        isLoading: true,
        isLoadingSmall: false,
        feedbackTypes: []
    },
    getters: {
        getStudio(state) {
            return state.studio
        },
        getConnection(state) {
            return state.socketConnection
        }
    },
    mutations: {
        signOut(state, token) {
            signOut(token,() => {
                store.commit('alerts/setAlertMessage', '😄 You have been signed out!');
            })
        },
        setStudio(state, payload) {
            state.studio = payload
        },
        setFeedbackTypes(state, payload) {
            state.feedbackTypes = payload
        },
        setConnection(state, payload) {
            state.socketConnection = payload
        },
        setIsLoading(state, payload) {
            state.isLoading = payload
        },
        setIsLoadingSmall(state, payload) {
            state.isLoadingSmall = payload
        },
        setContractTypes(state, payload) {
            state.contractTypes = payload
        },
    },
}