<template>
  <div class="feedback-wrapper">
    <div class="feedback-left-wrapper">
      <div class="feedback-top-bar">
        <div class="feedback-datetime-wrapper">
          <p class="text default font-weight-normal">{{feedbackDate}}</p>
          <p class="text default font-weight-normal">{{feedbackTime}}</p>
        </div>
        <div class="text default font-weight-normal highlighted">{{feedBack["type"]["value"]}}</div>
      </div>
      <p class="text default font-weight-normal">{{feedBack["text"]}}</p>
    </div>
    <span class="material-symbols-outlined delete-icon close-icon" title="Delete feedback" @click="$emit('deleteFeedback', this.feedBack['id'])">delete</span>

  </div>
</template>

<script>
export default {
  props: {
    index: {},
    feedBack: {}
  },
  emits: ["deleteFeedback"],
  computed: {
    feedbackDate() {
      if (!this.feedBack || !this.feedBack.date) return '';
      const datetimeString = this.feedBack.date;
      const [datePart] = datetimeString.split('T');
      const [year, month, day] = datePart.split('-');

      // Formatiere das Datum im Format TT.MM.YYYY
      const formattedDate = `${day}.${month}.${year}`;

      return formattedDate;
    },
    feedbackTime() {
      if (!this.feedBack || !this.feedBack.date) return '';
      const datetimeString = this.feedBack.date;
      const timePart = datetimeString.split('T')[1];
      const timeWithoutSeconds = timePart.split(':').slice(0, 2).join(':');
      return timeWithoutSeconds;
    }

  },
  data() {
    return {

    }
  },
  name: "feedbackModule"
}
</script>

<style scoped>

  .feedback-wrapper {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .feedback-left-wrapper {
    display: flex;
    flex-direction: column;
    row-gap: 5px;
  }

  .feedback-datetime-wrapper, .feedback-top-bar {
    display: flex;
    column-gap: 20px;
    width: fit-content;
  }

</style>