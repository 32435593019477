import { mapState, mapMutations } from 'vuex';

export const userMixin = {
    computed: {
        ...mapState({
            user: state => state.user.user,
            visitedScreens: state => state.user.visitedScreensLast,
            newNotifications: state => state.user.newNotifications,
            newInvitesCount: state => state.user.newInvitesCount
        }),
    },
    methods: {
        ...mapMutations('user', ['setUser', 'setVisitedScreens', 'setNewNotifications'])

    }
};