export default {
    namespaced: true,
    state: {
        user: {},
        visitedScreensLast: null,
        newNotifications: null,
        isCustomer: false
    },
    getters: {
        getUser(state) {
            return state.user
        },
        getUserRole(state) {
            return state.user['role']
        },
        getUserId(state) {
            return state.user['id']
        },
        getIsCustomer(state) {
            return state.isCustomer
        }
    },
    mutations: {
        setUser(state, payload) {
            state.user = payload
        },
        setIsCustomer(state, payload) {
            state.isCustomer = payload
        },
        updateOwnUserRole(state, payload) {
            if (state.user['role'].id === payload.id) {
                state.user['role'] = payload
            }
        },
        setVisitedScreens(state, payload) {
            state.visitedScreensLast = payload
        },
        setNewNotifications(state, payload) {
            state.newNotifications = payload
        },
    }
}