<template>
  <div id="settings-page-module-wrapper">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "SettingsPageModule"
}
</script>

<style>
#settings-page-module-wrapper {
  padding-top: 10px;
  width: calc(100% - 20px);
  height: 100%;
  margin-left: 20px;
}

</style>
