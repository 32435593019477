import {permissions, permissionType} from "@/Enums";
import store from "@/stores/Store";
import {getObjectiveSubObjectives} from "@/Scripts/API/ObjectivesAPI";
import {routeTo} from "@/Router";
const Holidays = require('date-holidays')


    export let baseApiUrl = process.env.VUE_APP_BASE_API_URL //|| "http://127.0.0.1:8000/api"
    export let baseMediaUrl = process.env.VUE_APP_BASE_MEDIA_URL //|| "http://127.0.0.1:8000"
    export let baseWsUrl = process.env.VUE_APP_BASE_WS_URL //||"ws://127.0.0.1:8000/api/ws"
    export let okr_frontend_url = process.env.VUE_APP_OKR_FRONTEND_URL// ||"http://127.0.0.1:8081"
    export let maxImageUploadSize = process.env.VUE_APP_MAX_IMAGE_UPLOAD_SIZE// ||"http://127.0.0.1:8081"



    export function checkPageViewPermission(bool) { // todo: Muss später für eigentliche funktionalität abgeändert werden
        if (bool) {
            routeTo('objectives')
        }
    }

    export function isUserInSpecificRoles(userRole, roles) {
    let result = false
    roles.forEach(role => {
        if(userRole === role) {
            result = true
        }
    })
    return result
}

    export function addObjectiveById(id, objective, objectives) {
    objectives.forEach((obj) => {
        if (obj.id === id) {
            if (!obj.subObjectives) {
                obj.subObjectives = [];
            }
            obj.subObjectives.push(objective);
            return;
        }
        if (obj.subObjectives) {
            addObjectiveById(id, objective, obj.subObjectives);
        }
    });
    }

    export function canUserPerformSinglePermissionAction(userRole, permission) {
        let canPerformAction = false
        userRole.permissions.forEach(entry => {
            if (entry['text_identifier'] === permission) {
                canPerformAction = true
            }
        })
        return canPerformAction
    }

    export function canUserPerformObjectiveAction(userRole, userID,objective, permissionTuple) {
        let canPerformAction = false
        userRole['permissions'].forEach(entry => {
            if (entry['text_identifier'] === permissionTuple['generalPermission']) {
                canPerformAction = true
            } else if (entry['text_identifier'] === permissionTuple['assignedPermission']) {
                objective['assigned_users'].forEach(entry => {
                    if (entry.id === userID) {
                        canPerformAction = true
                    }
                })
            }
        })
        return canPerformAction
    }

    export function checkIfUsersHaveRole(role, users) {
    let hasRole = false
    let roleAssignedUsers = []
    users.forEach(user => {
        if (user['role'].id == role.id) {
            hasRole = true
            roleAssignedUsers.push(user['username'])
        }
    })
    if (hasRole) {
        let usersString = " "
        roleAssignedUsers.forEach(username => {
            usersString += ", " + username
        })
        alert("You can't delete this role. The users" + usersString
            + ", have this role assigned. Please assign another role to these users, before you delete this one.")
    }
    return hasRole
}

    export function playSound(url) {
        let audio = new Audio('' + url);
        audio.volume = 0.2
        audio.play()
    }

    export function convertImage(image) {
        let formData = new FormData()
        formData.append('image_url', image)
        return formData
    }

    export function getObjectiveById(id, objectives) {
    let card = objectives.find(card => card.objective.id == id)
        return card.objective
    }

    export function getAnyObjectiveById(objectiveId, objectives) {
        for (let i = 0; i < objectives.length; i++) {
            const objective = objectives[i];
            if (objective.id == objectiveId) {
                return objective;
            } else if (objective['subObjectives']) {
                const subObjective = getAnyObjectiveById(objectiveId, objective['subObjectives']);
                if (subObjective) {
                    return subObjective;
                }
            }
        }
        return null;
    }

    function getDaysInYear(year) {
    let days = [];
    let date = new Date(year, 0, 1); // Erster Tag des Jahres

    while (date.getFullYear() === year) {
        let day = date.getDate();
        let month = date.getMonth() + 1; // Monate im JavaScript-Date-Objekt beginnen bei 0
        let formattedDate = date.getFullYear() + '-' + ('0' + month).slice(-2) + '-' + ('0' + day).slice(-2);

        days.push(formattedDate);

        date.setDate(date.getDate() + 1); // Zum nächsten Tag wechseln
    }

    return days;
}

// Beispielaufruf für das aktuelle Jahr

function getWeekdayAbbreviation(dateString) {
        dateString = '' + dateString
    let dateParts = dateString.split('-');
    let year = parseInt(dateParts[0]);
    let month = parseInt(dateParts[1]) - 1; // Monate im JavaScript-Date-Objekt beginnen bei 0
    let day = parseInt(dateParts[2]);
    let date = new Date(year, month, day);
    let weekdays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    let weekdayAbbreviation = weekdays[date.getDay()];
    return weekdayAbbreviation;
}

export function getCalendarWeeks(year) {
    let weeks = [];
    let date = new Date(year, 0, 1); // Erster Tag des Jahres
    let index = 2
    let days = getDaysInYear(year)
    let isCommonYear = getWeekdayAbbreviation(days[0]) === 'Thu' || getWeekdayAbbreviation(days.length-1) === 'Thu'
    let firstSundayString = ''
    let startIndex = 0
    for (let i = 0; i < days.length; i++) {
        if (getWeekDay(days[i]) === 4) {
            startIndex = i+4
            let firstThursday = new Date(days[i])
            let firstMonday = new Date(firstThursday)
            firstMonday.setDate(firstThursday.getDate() - 3)
            let firstSunday = new Date(firstThursday)
            firstSunday.setDate(firstMonday.getDate() + 6)
            firstSundayString = formatDateToYYYYMMDD(firstSunday)

            let firstWeek = {
                "star": formatDateToYYYYMMDD(firstMonday),
                "end": firstSundayString,
                "index": 1
            }

            weeks.unshift(firstWeek)
            break
        }
    }
    for (let i = startIndex; i < days.length; i+=7) {
        let week = {
            "start": days[i],
            "end": days[i+6],
            "index": index
        }
        index++
         if(week['end'] === undefined && !isCommonYear) {
             return weeks
         } else if (week['end'] === undefined && isCommonYear) {
             let start = new Date(week['start'])
             let endDate = new Date(start)
             endDate.setDate(start.getDate() + 6)
             let day = endDate.getDate();
             let month = endDate.getMonth() + 1; // Monate im JavaScript-Date-Objekt beginnen bei 0
             let formattedDate = endDate.getFullYear() + '-' + ('0' + month).slice(-2) + '-' + ('0' + day).slice(-2);
             week['end'] = formattedDate
         }
        weeks.push(week)
    }
    return weeks;
    //return calendarWeeks;
    }

    // Wenn das Jahr ein Schaltjahr ist und der erste Tag des Jahres kein Montag ist, füge eine zusätzliche Woche hinzu

function isLeap(year) {
    return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
}

export function getWeekDay(dateString) {
    const date = new Date(dateString);
    let weekday = date.getDay();
    if (weekday === 0) {
        weekday = 7; // Sonntag auf 7 ändern
    }
    return weekday
}

export function getWeekNumber(date) {
        //let date = new Date('' + dateString)
    let year = date.getFullYear()
    let weeks = getCalendarWeeks(year)
    let weekNumber = 0
    for (let i = 0; i < weeks.length; i++) {
        let week = weeks[i]
        let start = new Date(week['start']).getTime()
        let end = new Date(week['end']).getTime()
        let dateTimestamp = date.getTime()
        if (start <= dateTimestamp && dateTimestamp <= end) {
            weekNumber = week['index']
            break
        }
    }
    return weekNumber
}

export function formatDateToYYYYMMDD(date) {
    let year = date.getFullYear();
    let month = ("0" + (date.getMonth() + 1)).slice(-2);
    let day = ("0" + date.getDate()).slice(-2);
    return year + "-" + month + "-" + day;
}

export function getToday() {
    let currentDate = new Date();
// Formatieren des Datums als "YYYY-MM-DD"
    let year = currentDate.getFullYear();
    let month = String(currentDate.getMonth() + 1).padStart(2, '0');
    let day = String(currentDate.getDate()).padStart(2, '0');
    let formattedDate = `${year}-${month}-${day}`//year + '-' + month + '-' + day;
    return formattedDate
}

export function getTodayDDMMYYYY() {
    let currentDate = new Date();
// Formatieren des Datums als "YYYY-MM-DD"
    let year = currentDate.getFullYear();
    let month = String(currentDate.getMonth() + 1).padStart(2, '0');
    let day = String(currentDate.getDate()).padStart(2, '0');
    let formattedDate = `${day}.${month}.${year}`//year + '-' + month + '-' + day;
    return formattedDate
}

export function isDateInCommonYear(dateString, year) {
    let date = new Date (dateString)
    let weeks = getCalendarWeeks(year)
    let result = false
    for (let i = 0; i < weeks.length; i++) {
        let start = weeks[i]['start']
        let end = weeks[i]['end']
        let startDate = new Date(start)
        let endDate = new Date(end)

        if (startDate <= date && date <= endDate)  {
            result = true
            break
        }
    }
    return result
}

export function areBoundsOutsideCommomYear(startString, deadlineString, year) {
    let start = new Date(startString).getFullYear()
    let deadline = new Date(deadlineString).getFullYear()
    let weeks = getCalendarWeeks(year)
    let lowerBound = new Date(weeks[0]['start']).getFullYear()
    let upperBound = new Date(weeks[weeks.length - 1]['end']).getFullYear()

    let result = start < lowerBound && deadline > upperBound
    return result
}

export function checkDateIsHoliday(date) {
    let result = false
    const hd = new Holidays('DE', 'BW')
    let holidays = hd.getHolidays(date.getFullYear())
    holidays = holidays.filter((holiday) => holiday.type === 'public');
    for (let i = 0; i < holidays.length; i++) {
        let holiday = new Date(holidays[i].date)
        let utcDate =  Date.UTC(date.getFullYear(), date.getMonth(), date.getDate());
        let utcHoliday =  Date.UTC(holiday.getFullYear(), holiday.getMonth(), holiday.getDate());
        if (utcDate == utcHoliday) {
            result = true
            break
        }
    }
    return result
}

export function calculateDeadlineDiff(deadLine) {
    if (deadLine) {
        let desiredDate = new Date(deadLine); // Ersetze '2023-06-10' mit dem gewünschten Datum
        // Heutiges Datum
        let today = new Date();
        let timeDiff = desiredDate.getTime() - today.getTime();
        let daysDiff = Math.ceil(timeDiff / (1000 * 3600 * 24));
        //this.daysLeft = daysDiff
        return daysDiff
    }
}

export function compareArrays(arr1, arr2) {
    if (arr1.length !== arr2.length) {
        return false;
    }

    for (let i = 0; i < arr1.length; i++) {
        if (arr1[i] !== arr2[i]) {
            return false;
        }
    }

    return true;
}

export function sortObjectivesAlphabetically(objectives) {
    let sorted = objectives.sort((a, b) => {
        let objectiveA = a.title.toLowerCase();
        let objectiveB = b.title.toLowerCase();

        if (objectiveA < objectiveB) {
            return -1;
        }
        if (objectiveA > objectiveB) {
            return 1;
        }
        return 0;
    });
    return sorted
}

export function  sortPermissionsByType(permissions) {
    let result = []
    for (let typeValue of Object.values(permissionType)) {
        let filteredPermissions = permissions.filter(perm => {
            return perm['permission_type']['text_identifier'] == typeValue
        })
        result.push({'heading': typeValue, options: filteredPermissions})
    }
    return result
}

export function getRandomInt(max) {
    return Math.floor(Math.random() * max);
}

export function splitProgressString(string) {
    let str = string
    let splitString = str.split('%');
    let value = splitString[0]
    return parseInt(value)
}

export function getTimeStampYear(timestampStr) {
    const timestamp = new Date(timestampStr);
    const year = timestamp.getFullYear();
    return year;
}

export function getTimeStampDate(timestampStr) {
    const timestamp = new Date(timestampStr);
    return timestamp
}

export function getTimeStampDateString(timestampStr) {
    const timestamp = new Date(timestampStr);
    const year = timestamp.getFullYear();
    const month = (timestamp.getMonth() + 1).toString().padStart(2, '0');
    const day = timestamp.getDate().toString().padStart(2, '0');
    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate
}

export function formatTimeSTamp(timeStamp) {
    let timeStampObject = new Date(timeStamp)
    const timeStr = timeStampObject.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    return timeStr
}

export async function fetchObjectiveSubObjectives(token, studioID, objectiveID, finished) {
     await getObjectiveSubObjectives(token, studioID, objectiveID, response => {
        let payload = {
            "id": objectiveID,
            "subObjectives": response
        }
        store.commit('objectives/setSbuObjectives', payload)
        finished(response)
    })}
