export default {
    namespaced: true,
    state: {
        alertMessage: ''
    },
    mutations: {
        setAlertMessage(state, payload) {
            state.alertMessage = payload
        },
    },
}