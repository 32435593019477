<template>
  <div class="loading-animation">
    <span class="loading-text">Loading</span>
    <span class="dot dot1">.</span>
    <span class="dot dot2">.</span>
    <span class="dot dot3">.</span>
  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "LoadingTextComponent",
  computed: {
    ...mapState({
      isDarkmode: state => state.theme.isDarkmode
    }),


  },
}
</script>

<style scoped>

@keyframes dotFade {
  0%, 80%, 100% { opacity: 0; }
  40% { opacity: 1; }
}

.loading-animation {
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  color: var(--highlight-color-primary);
}

.loading-text {
  display: inline-block;
  margin-left: 10px;
}

.dot {
  animation: dotFade 2.4s infinite;
}

.dot1 {
  animation-delay: 0.0s;
}

.dot2 {
  animation-delay: 0.4s;
}

.dot3 {
  animation-delay: 0.8s;
}


</style>