import { mapState, mapMutations } from 'vuex';

export const notificationsMixin = {
    computed: {
        ...mapState({
            notifications: state => state.notifications.notifications,
            newNotifications: state => state.notifications.newNotifications
        }),
    },
    methods: {
        ...mapMutations('notifications', ['setNotifications', 'setNewNotificationsCount'])

    }
};