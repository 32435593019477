<template>
  <form-background>
    <template #form>
      <form-wrapper :heading="'Feedback'" :amount-content-screens="1" :button-options="buttonOptions" @closeForm="$emit('closeForm')" @clickSubmit="submitFeedback">
        <template #contentBox0>
          <form-row :title="'Feedback type'" :type="'select'" :select-options="feedbackTypes" :value-field="'value'" :place-holder="'Select feedback type...'" v-model="selectedFeedbackType"></form-row>
          <form-row :title="'Your feedback'" :type="'textarea'" :place-holder="'Type in your feedback...'" v-model="feedback"></form-row>
        </template>
      </form-wrapper>
    </template>
  </form-background>
</template>

<script>
import FormBackground from "@/components/formComponents/formBackground";
import FormWrapper from "@/components/formComponents/formWrapper";
import FormRow from "@/components/formComponents/formRow";
import {performPostRequest, performRequest} from "@/Scripts/API/Services/APIService";
import APIEndpoints from "@/Scripts/API/Services/APIEndpoints";
import EventBus from "@/Events/EventBus";
import {clientEvents} from "@/Events/clientEventEnums";
import {alertTypes} from "@/Enums";
export default {
  name: "feedbackForm",
  components: {FormRow, FormWrapper, FormBackground},
  emits: ["closeForm"],
  data() {
    return {
      buttonOptions: {
        "hasSubmit": true,
        "hasDelete": false
      },
      feedbackTypes: null,
      selectedFeedbackType: null,
      feedback: ""
    }
  },
  async mounted() {
    this.feedbackTypes = await performRequest(APIEndpoints.getFeedBackTypes)
    console.log(this.feedbackTypes)
  },
  methods: {
    submitFeedback() {
      if (!this.selectedFeedbackType) {
        EventBus.emit(clientEvents.ALERT_EVENT, "Please select an event type", alertTypes.INFORMATION)
        return
      } else if (this.feedback === "") {
        EventBus.emit(clientEvents.ALERT_EVENT, "Please type in your feedback", alertTypes.INFORMATION)
        return
      }
      const feedbackObject = {"feedback_type_id": this.selectedFeedbackType["id"], "feedback": this.feedback}
      this.$emit("submit", feedbackObject)
      this.$emit("closeForm")
    }
  }
}
</script>

<style scoped>

</style>