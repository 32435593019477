
export const alertTypes = {
    SUCCESS: 0,
    INFORMATION: 1,
    WARNING: 2
}

    export const baseRoles = {
        colleague: "Colleague",
        customer: "Customer",
    }

    export const formType = {
        addRole: "Add role",
        editRole: "Edit role",
        addTag: "Add tag",
        addUser: "Add user",
        editUser: "Edit user",
        addSubObjective: "Add subobjective",
        editObjective: "Edit objective",
        addImage: "New image"
    }

    export const objectiveFilterOptions= {
        all: "All",
        my: "My",
        tags: "Tags",
        archived: "Archived"
    }

    export const studioFilterOptions= {
        colleagues: "Colleagues",
        customer: "Customer",
        tags: "Tags",
        roles: "Roles",
        all: "All"
    }

    export const gridSizes = {
        small: "small",
        big: "big",
    }

    export const priorities = {
        A: "A",
        B: "B",
        C: "C",
        D: "D",
    }

    export const socketEventType = {
        newTag: 0,
        deleteTag: 1,
        addUser: 2,
        deleteUser: 3,
        editUser: 4,
        addRole: 5,
        addObjective: 6,
        deleteObjective: 7,
        editObjective: 8,
        addSubObjective: 9,
        editSubObjective: 10,
        deleteSubObjective: 11,
        editRole: 12,
        deleteRole: 13,
        addComment: 14,
        deleteComment: 15,
        setKudos: 17,
        newNotification: 18
    }

    export const alertType = {
        info: 1,
        warning: 2
    }

    export const permissions = {
        logIn: "log_in", //
        objectivesCreate: "objectives_create", //
        objectivesEdit: "objectives_edit", //
        objectivesEditAssigned: "objectives_edit_assigned", //
        objectivesDelete: "objectives_delete", //
        objectivesDeleteAssigned: "objectives_delete_assigned", //
        objectiveEvaluate: "objectives_evaluate", //
        objectiveEvaluateAssigned: "objectives_evaluate_assigned", //
        objectiveSeeLogging: "objectives_see_logging", //
        objectiveSeeAssignedLogging: "objectives_see_assigned_logging", //
        logTime: "log_time", //
        userCreate: "user_create", //
        userEdit: "user_edit", //
        userDelete: "user_delete", //
        tagCreate: "tag_create", //
        tagDelete: "tag_delete",//
        roleCreate: "role_create",//
        roleEdit: "role_edit", //
        roleDelete: "role_delete", //
        seeAllObjectives: 'see_all', //
        seeAssigned: 'see_assigned', //
        seeTagMatchingObjectives: 'see_tag_matching', //
        seeArchievedObjectives: 'see_archieved', //
        seeAssignedComments: 'see_assigned_comments', //
        writeAssignedComment: 'write_assigned_comment', //
        seeAllComments: 'see_all_comments', //
        writeAllComments: 'write_all_comment',//
        seeAssignedImages: 'see_assigned_images', //
        uploadAssignedImage: 'upload_assigned_image', //
        seeAllImages: 'see_all_images', //
        uploadAllImages: 'upload_all_images', //
        seeAllEvaluation: 'see_all_evaluation', //
        seeAssignedEvaluation: 'see_assigned_evaluation', //
        seePriorities: 'see_priorities',
        seeAssignedObjectiveUsers: 'see_assigned_objective_users', //
        seeAllObjectiveUsers: 'see_all_objective_users'
    }

    export const roleType = {
        colleague: 1,
        customer: 2
    }

    export const objectiveViewMode = {
        objective: 0,
        calendarWeeks: 1
    }

    export const socketResponseStatus = {
        ok: 200,
        failed: 400
    }

    export const timelogType = {
        objective: 0,
        user: 1
}

    export const studioModules = {
        all: "All",
        users: "Users",
        customers: "Customers",
        roles: "Roles",
        tags: "Tags",
        logging: "Logging"
    }

    export const objectiveSwitchItems = {
        active: "Active",
        hidden: "Hidden",
    }

    export const roleSwitchItems = {
        all: "All",
        user: "Colleague",
        customer: "Customer"
    }

    export const vacationViewModes = {
        timeLogs: "Time logs",
        vacationDays: "Vacation Days",
            sickDays: "Sick Days"
    }

    export const editUserType = {
        editName: 0,
        editRole: 1,
        editContract: 2,
        editTags: 3,
        editObjectives: 4,
        editEmail: 5,
        editPassword: 6,
        editStart: 7,
        editEnd: 8,
        editBirthday: 9,
        editVacationDays: 10,
        editDailyWorkHours: 11,
        editWorkDays: 12,
        editDescription: 13,
        editShowInLogs: 14,
        editOvertimeStart: 15,
        editOvertimeEnd: 16
    }

    export const editObjectiveType = {
        editTitle: 0,
        editStart: 1,
        editTags: 2,
        editScale: 3,
        editUsers: 4,
    }

    export const permissionType = {
    general: 'General',
    objective: 'Objective',
    filter: 'Filter',
    time: 'Time',
    users: 'Users',
    roles: 'Roles',
    tags: 'Tags',
    }

    export const themes = {
        LIGHT_MODE: {
            value: "light",
            visibleName: "Light"
        },
        DARK_MODE: {
            value: "dark",
            visibleName: "Dark"
        },
        SYSTEM: {
            value: "system",
            visibleName: "System"
        }
    }