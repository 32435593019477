<template>
  <div id="notifications-wrapper">
    <div id="top-wrapper">
      <p class="text medium font-weight-normal">Notifications</p>
      <button id="clear-all-button" class="close-icon default" @click="deleteAllNotifications">Clear all</button>
    </div>
    <div id="notifications-content" :class="{'no-notifications': notifications.length === 0 }">
      <div id="notifications-scroll-wrapper" v-if="notifications.length > 0">
        <notification-module v-for="entry in notifications" :key="entry" :entry="entry" @removeEntry="removeEntry"/>
      </div>
      <circle-spinner-small-c-p v-else-if="isLoading"/>
      <p class="default text" v-else>No new notifications</p>
    </div>
  </div>
</template>

<script>



import NotificationModule from "@/components/SideBar/Notifictions/Modules/NotificationModule";
import {performRequest, performPostRequest, performRequestWithQuery} from "@/Scripts/API/Services/APIService";
import APIEndpoints from "@/Scripts/API/Services/APIEndpoints";
import {notificationsMixin} from "@/mixins/notificationsMixin";
import {userMixin} from "@/mixins/userMixin";
import CircleSpinnerSmallCP from "@/components/miniComponents/UIComponents/Loading/Spinners/CircleSpinnerSmallCP";
import EventBus from "@/Events/EventBus";
import {clientEvents} from "@/Events/clientEventEnums";
import {alertTypes} from "@/Enums";
export default {
  name: "NotificationsComponent",
  components: {CircleSpinnerSmallCP, NotificationModule},
  mixins: [notificationsMixin, userMixin],
  data() {
    return {
      notifications: [],
      isLoading: true

    }
  },
  mounted() {
    this.resetNotificationsCountCount()
    performRequest(APIEndpoints.getNotifications).then(data => {
      this.isLoading = false
      this.notifications = data
    })
  },
  computed: {
  },
  methods: {
    removeEntry(id) {
      this.notifications = this.notifications.filter(notification => {
        return notification.id !== id
      })
    },
    deleteAllNotifications() {
      performRequestWithQuery(APIEndpoints.deleteNotifications, "all").then(() => {
        this.notifications = []
        EventBus.emit(clientEvents.ALERT_EVENT, "All notifications deleted!", alertTypes.SUCCESS)
      })
    },
    resetNotificationsCountCount() {
      const screens = this.visitedScreens
      screens['notifications_visited_last'] = new Date().toISOString()
      performPostRequest(APIEndpoints.setScreenVisits, this.visitedScreens).then(() => {
        const newNotificationsCount = 0
        this.setNewNotificationsCount(newNotificationsCount)
      })
    },
  }
}
</script>

<style scoped>

#notifications-wrapper {
  position: absolute;
  width: 400px;
  height: fit-content;
  max-height: 400px;
  min-height: 150px;
  z-index: 1000;
  top: 50px;
  left: -390px;
  border-radius: var(--border-radius);
  background-color: var(--profile-quick-setings-background);
  box-shadow: var(--profile-quick-setings-shadow);
  padding-block: 10px;
  padding-inline: 20px;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}

#top-wrapper {
  display: flex;
  justify-content: space-between;
  height: fit-content;
}

#clear-all-button {
  border: None;
  background: None;
  text-decoration: underline;
}

#notifications-content {
  flex: 1;
  overflow: auto;
  display: flex;
  justify-content: start;
  align-items: start;
}

#notifications-content.no-notifications {
  justify-content: center !important;
  align-items: center !important;
}

#notifications-scroll-wrapper {
  height: fit-content;
  max-height: 100%;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  padding-right: 10px;
  align-items: flex-start;
  justify-content: flex-start;
}

#notifications-scroll-wrapper::-webkit-scrollbar {
  display: block;
  width: 12px; /* Breite der vertikalen Scrollbar */
  height: 12px; /* Höhe der horizontalen Scrollbar */
}

/* Track (Hintergrund der Scrollbar) */
#notifications-scroll-wrapper::-webkit-scrollbar-track {
  background: var(--section-background-color);
  border-radius: 10px;
}

/* Handle (der bewegliche Teil) */
#notifications-scroll-wrapper::-webkit-scrollbar-thumb {
  background: var(--highlight-color-primary);
  border-radius: 10px;
}

@media (max-width: 540px) {

  #notifications-wrapper {
    width: 300px;
    left: -230px;
  }
}


</style>