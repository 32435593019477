<template>
  <form-background>
    <template #form>
      <form id="settings-background">
        <div class="form-container-top medium">
          <span class="text">Settings</span>
          <span class="material-symbols-outlined close-icon" @click="closeSettings">close</span>

        </div>
        <div id="settings-content">
          <div id="selection-wrapper" class="default">
            <span class="text selection-item" :class="{active: currentIndex === page.index}" v-for="page in Object.values(settingPages)" :key="page.index" @click="selectPage(page.index)">{{page.name}}</span>
          </div>
          <div id="content-wrapper">
            <theme-module v-if="currentIndex === settingPages.THEME.index"/>
            <credentials-module v-if="currentIndex === settingPages.ACCOUNT.index"/>
            <!--<timezone-module  v-if="currentIndex === settingPages.TIME_ZONE.index"/>-->
          </div>
        </div>
      </form>
    </template>
  </form-background>
</template>
<script>
import EventBus from "@/Events/EventBus";
import {clientEvents} from "@/Events/clientEventEnums";
import ThemeModule from "@/components/Settings/modules/themeModule";
import FormBackground from "@/components/formComponents/formBackground";
import CredentialsModule from "@/components/Settings/modules/credentialsModule";

export default {
  name: "settingsComponent",
  components: {CredentialsModule, FormBackground, ThemeModule},
  data() {
    return {
      settingPages: {
        THEME: {
          index: 0,
          name: "Theme"
        },
        ACCOUNT: {
          index: 1,
          name: "Account"
        },
      /*  TIME_ZONE: {
          index: 1,
          name: "Timezone"
        }*/
      },
      currentIndex: 0
    }
  },
  methods: {
    closeSettings() {
      EventBus.emit(clientEvents.SHOW_SETTINGS)
    },
    selectPage(index) {
      this.currentIndex = index
    }
  }
}
</script>

<style scoped>
@import "@/styles/FormStyle.css";

.form-background {
  z-index: 2;
}

.form-container-top {
  width: auto;
  margin-left: 20px;
  margin-right: 20px;
  border-bottom: var(--space-devider-color) 1px solid;
}

#settings-background {
  width: calc(100% - 40px);
  min-width: 300px;
  max-width: 600px;
  position: absolute;
  height: 350px;
}

#settings-content {
  height: calc(100% - 40px - 43px);
  margin: 15px 20px 20px;
  display: flex;
  flex-direction: column;
}

.selection-item {
  cursor: pointer;
  width: fit-content;
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 5px;
}

.selection-item.active, .selection-item:hover {
  opacity: 1;
  background-color: var(--highlight-color-primary);
  color: var(--text-color-light-only);
}

#selection-wrapper {
  width: 100%;
  height: fit-content;
  display: flex;
  padding-bottom: 10px;
  align-items: center;
  justify-content: space-evenly;
  border-bottom: var(--space-devider-color) 1px solid;
}

#content-wrapper {
  width: 100%;
  height: 100%;
}

@media(min-width: 530px) {
  #settings-content {
    flex-direction: row;
    margin-top: 0;
  }

  #selection-wrapper {
    width: 150px;
    padding-top: 15px;
    min-height: fit-content;
    height: 100%;
    flex: available;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    justify-content: flex-start;
    margin-bottom: 0;
    border: none;
    border-right: var(--space-devider-color) 1px solid;
  }
}


</style>