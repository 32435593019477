import APIEndpoints from "@/Scripts/API/Services/APIEndpoints";
import {routeTo} from "@/Router";

export function getOwnUserData(token, callBack) {
    APIEndpoints.getOwnUserdata(token).then(response => {
        callBack(response.data)
    }).catch(err => {
        if (err.status === 404 || err.status === 401) {
            this.$cookies.remove('authToken')
        }
        routeTo('Authentication')
})
}

export function getOwnUserStudioInvites(token, callBack) {
    APIEndpoints.getOwnUserStudioInvites(token).then(response => {
        callBack(response.data)
    }).catch(err => {
        if (err.status === 404 || err.status === 401) {
            this.$cookies.remove('authToken')
        }
        routeTo('Authentication')
    })
}
export function getOwnUserStudios(token, callBack) {
    APIEndpoints.getOwnUserStudios(token).then(response => {
        const data = response.data
        callBack(data)
    }).catch(err => {
        if (err.status === 404 || err.status === 401) {
            //this.$cookies.remove('authToken')
        }
        //routeTo('Authentication')
    })
}

export function getStudioUsers(token,studioID ,callBack) {
    APIEndpoints.getStudioUsers(token, studioID).then(response => {
        callBack(response.data)
    }).catch(err => console.log('something went wrong'))
}


export function editUser(token,studioID, userID, username, email, role, tags, password, objectives ,callBack) {
    APIEndpoints.editUser(token, studioID, userID, username, email, role, tags, password, objectives).then(response => {
        callBack(response.data)
    }).catch(err => console.log('something went wrong'))
}

export function getUserAssignedObjectives(token,studioID, userID, callBack) {
    APIEndpoints.getUserAssignedObjectives(token, studioID, userID).then(response => {
        callBack(response.data)
    }).catch(err => console.log('something went wrong'))
}

export function getUserAssignedTags(token,studioID, userID, callBack) {
    APIEndpoints.getUserAssignedTags(token, studioID, userID).then(response => {
        callBack(response.data)
    }).catch(err => console.log('something went wrong'))
}


export function getVacationDays(token,studioID, userID,year, month,callBack) {
    APIEndpoints.getVacationDays(token, studioID, userID,year, month).then(response => {
        callBack(response.data)
    }).catch(err => console.log('something went wrong'))
}

export function getSickDays(token,studioID, userID,year, month,callBack) {
    APIEndpoints.getSickDays(token, studioID, userID,year, month).then(response => {
        callBack(response.data)
    }).catch(err => console.log('something went wrong'))
}

export function putVacationDay(token,studioID, userID,year, month, date, callBack) {
    APIEndpoints.putVacationDay(token, studioID, userID,year, month, date).then(response => {
        callBack(response.data)
    }).catch(err => console.log('something went wrong'))
}

export function putSickDay(token,studioID, userID,year, month, date, callBack) {
    APIEndpoints.putSickDay(token, studioID, userID,year, month, date).then(response => {
        callBack(response.data)
    }).catch(err => console.log('something went wrong'))
}

export function toggleIsHidden(token,studioID, userID) {
    APIEndpoints.toggleUserIsHidden(token, studioID, userID).catch(err => console.log('something went wrong'))
}

export function resetOvertime(token,studioID, userID, callBack) {
    APIEndpoints.resetOvertime(token, studioID, userID).then(response => {
        callBack(response.data)
    }).catch(err => console.log('something went wrong'))
}

export function getNotifications(token,studioID ,userID, callBack) {
    APIEndpoints.getNotificationAmount(token, studioID, userID).then(response => {
        callBack(response.data)
    }).catch(err => console.log('something went wrong'))
}

export function getNotificationsForDate(token,studioID ,userID, date, callBack) {
    APIEndpoints.getNotificationsForDate(token, studioID, userID, date).then(response => {
        callBack(response.data)
    }).catch(err => console.log('something went wrong'))
}

export function putLastScreenVisit(token,studioID ,userID, field, callBack) {
    APIEndpoints.putLastScreenVisit(token, studioID, userID, field).then(response => {
        callBack(response.data)
    }).catch(err => console.log('something went wrong'))
}
