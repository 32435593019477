<template>
  <main-component-wrapper>
    Invites
   <template #content>
     <div id="content">
       <invites-option-bar @clickAdd="toggleShowForm"/>
       <div id="invites-wrapper">
          <invites-list-module @denyInvite="denyInvite" @acceptInvite="acceptInvite" v-for="invite in invites" :key="invite.id" :invite="invite"></invites-list-module>
       </div>
     </div>

    </template>
  </main-component-wrapper>
  <add-new-studio-form v-if="showForm" @closeForm="toggleShowForm"/>
</template>

<script>

import {mapGetters, mapMutations, mapState} from "vuex";
import MainComponentWrapper from "@/components/MainComponentWrapper";

import StudiosOptionBar from "@/components/studios/modules/studiosOptionBar";
import {addableContentMixin} from "@/mixins/addableContentMixin";
import AddNewStudioForm from "@/components/studios/forms/studioForm";
import {getOwnUserStudioInvites, getOwnUserStudios} from "@/Scripts/API/UserAPI";
import StudioListModule from "@/components/studios/modules/studioListModule";
import InvitesListModule from "@/components/invites/modules/invitesListModule";
import InvitesOptionBar from "@/components/invites/modules/invitesOptionBar";
import EventBus from "@/Events/EventBus";
import {clientEvents} from "@/Events/clientEventEnums";
import {acceptInvite, denyInvite} from "@/Scripts/API/StudioAPI";
import {performPostRequest} from "@/Scripts/API/Services/APIService";
import APIEndpoints from "@/Scripts/API/Services/APIEndpoints";
import {userMixin} from "@/mixins/userMixin";
import {invitesMixin} from "@/mixins/invitesMixin";
import {alertTypes} from "@/Enums";

export default {
  name: "InvitesComponent",
  components: {InvitesOptionBar, InvitesListModule, AddNewStudioForm, MainComponentWrapper},
  mixins: [addableContentMixin, userMixin, invitesMixin],
  data() {
    return {
    }
  },
  computed: {
    ...mapState({
    }),

  },
  mounted() {
    if(this.visitedScreens) {
      this.visitedScreens['invites_visited_last'] = new Date().toISOString()
      performPostRequest(APIEndpoints.setScreenVisits, this.visitedScreens).then(() => {
        const newInvitesCount = 0
        this.setNewInvitesCount(newInvitesCount)
      })
    }
    /*EventBus.on(clientEvents.INVITES_LOADING_FINISHED, () => {
      this.resetInvitesCount()
    })*/

  },

  methods: {
    resetInvitesCount() {
      this.visitedScreens['invites_visited_last'] = new Date().toISOString()
      performPostRequest(APIEndpoints.setScreenVisits, this.visitedScreens).then(() => {
        const newInvitesCount = 0
        this.setNewInvitesCount(newInvitesCount)
      })
    },
    denyInvite(invite) {
      denyInvite(this.$cookies.get('authToken'), invite['id'], invite['studio']['id'], () => {
        EventBus.emit(clientEvents.ALERT_EVENT, "Invite denied!", alertTypes.SUCCESS)
        const newInvites = this.invites.filter(obj => { return parseInt(obj.id) !== parseInt(invite.id) })
        this.setInvites(newInvites)

      })
    },
    acceptInvite(invite) {
      acceptInvite(this.$cookies.get('authToken'), invite['id'], () => {
        EventBus.emit(clientEvents.ALERT_EVENT, "Invite accepted!", alertTypes.SUCCESS)
        const newInvites = this.invites.filter(obj => { return parseInt(obj.id) !== parseInt(invite.id) })
        this.setInvites(newInvites)
      })
    },
  },
}
</script>


<style scoped>

  #invites-wrapper {
    flex: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    overflow: auto;
  }

  #content {
    flex: 1;
    display: flex;
    row-gap: 20px;
    flex-direction: column;
  }






</style>