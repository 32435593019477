<template>
  <div id="wrapper">
    <span id="search-icon" class="material-symbols-outlined"> search </span>
    <input v-model="text" @input="textDidChange(text)" id="input" placeholder="Search..." type="search">
  </div>
</template>

<script>
import {mapState,} from 'vuex';

export default {
  name: "SearchbarComponent",
  computed: {
    ...mapState({
      isDarkmode: state => state.theme.isDarkmode,
    })
  },
  data() {
    return {
      text: ''
    }
  },
  methods: {
    textDidChange(text) {
      this.$emit('textDidChange', text)
    }
  }
}
</script>

<style scoped>


#wrapper {

  display: flex;
  flex-direction: row !important;
  width: 100%;
  min-width: 10px;
  height: 40px;
  border-radius: var(--border-radius);
  /* padding: 0 .5em;*/
  background-color: var(--searchbar-background-color);
}

#input {
  width: 100%;
  height: 40px;
  border-radius: var(--border-radius);
  border: none;
  background: none;
  color: var(--text-color-main);
}

textarea:focus, input:focus{
  outline: none;
}

select::-ms-expand {
  display: none;
}

#select::after {

  display: none;
}

#search-icon {
  display: flex;
  align-items: center;
  margin-left: 10px;
}

select#format {
  background: none;
}

  .material-symbols-outlined {

    font-size: 30px;
    color: var(--highlight-color-primary);
    font-variation-settings:
        'FILL' 0,
        'wght' 400,
        'GRAD' 200,
        'opsz' 48
  }

  @media (min-width: 751px) {
    #wrapper {
      max-width: 600px;
    }

  }
</style>