<template>
  <div class="lds-roller" :id="'lds-roller' + id"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
<!--  <div class="checkmark draw hidden" :id="'checkmark' + id"></div>-->
</template>

<script>
import {mapGetters, mapState} from "vuex";
import {watch} from "vue";


export default {
  name: "CircleSpinnerSmallCP",
  props: {id: {}},

  computed: {
    ...mapState({
      isDarkmode: state => state.theme.isDarkmode,
      isLoadingSmall: state => state.studio.isLoadingSmall
    }),
  },
  mounted() {
  },
  methods: {
    startAnimation() {
      document.getElementById('lds-roller' + this.id).classList.add('start')
    },
    endAnimation() {
      document.getElementById('lds-roller'  + this.id).classList.remove('start')

    }
  }
}
</script>

<style scoped>
.lds-roller {
  display: none;
  position: relative;
  width: 40px;
  height: 40px;
}

.lds-roller.start {
  display: inline-block;
}
.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 20px 20px;
}
.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background: var(--highlight-color-primary);
  margin: -2px 0 0 -2px;
}
.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}
.lds-roller div:nth-child(1):after {
  top: 31px;
  left: 31px;
}
.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}
.lds-roller div:nth-child(2):after {
  top: 34px;
  left: 28px;
}
.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}
.lds-roller div:nth-child(3):after {
  top: 35px;
  left: 24px;
}
.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}
.lds-roller div:nth-child(4):after {
  top: 36px;
  left: 20px;
}
.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}
.lds-roller div:nth-child(5):after {
  top: 35px;
  left: 16px;
}
.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}
.lds-roller div:nth-child(6):after {
  top: 34px;
  left: 12px;
}
.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}
.lds-roller div:nth-child(7):after {
  top: 31px;
  left: 8px;
}
.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}
.lds-roller div:nth-child(8):after {
  top: 28px;
  left: 6px;
}
@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.checkmark {}

.checkmark.hidden {
  display: none;
}

.checkmark.draw:after {
  animation-duration: 800ms;
  animation-timing-function: ease;
  animation-name: checkmark;
  transform: scaleX(-1) rotate(135deg);
}
.checkmark:after {
  opacity: 1;
  height: 40px;
  width: calc(1.75em * (40 / 56));
  transform-origin: left top;
  border-right: 3px solid var(--highlight-color-primary);
  border-top: 3px solid var(--highlight-color-primary);
  content: "";
  position: absolute;
}

@keyframes loader-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes checkmark {
  0% {
    height: 0;
    width: 0;
    opacity: 1;
  }
  20% {
    height: 0;
    width: calc(1.75em * (40 / 56));
    opacity: 1;
  }
  40% {
    height: 40px;
    width: calc(1.75em * (40 / 56));
    opacity: 1;
  }
  100% {
    height: 40px;
    width: calc(1.75em * (40 / 56));
    opacity: 1;
  }
}
</style>