<template>
  <div class="profile-quick-menu">
      <ul class="quick-menu-list">
        <li class="list-row" @click="toggleShowChangeProfilePictureForm">
          <span class="material-symbols-outlined text">account_circle</span>
          <p class="text default list-item">Change profile picture</p>
        </li>

        <li class="list-row" @click="showSettings">
          <span class="material-symbols-outlined text">settings</span>
          <p class="text default list-item">Settings</p>
        </li>
        <li class="list-row" @click="signOut">
          <span class="material-symbols-outlined text">logout</span>
          <p class="text default list-item">Sign out</p>
        </li>
      </ul>
  </div>

  <add-profile-picture-form v-if="showChangeProfilePictureForm" @uploadProfilePicture="uploadProfilePicture" @closeForm="toggleShowChangeProfilePictureForm"/>

</template>

<script>
import AddProfilePictureForm from "@/components/profile/forms/addProfilePictureForm";
import {convertImage} from "@/Utils";
import {uploadImage} from "@/Scripts/API/Services/APIService";
import APIEndpoints from "@/Scripts/API/Services/APIEndpoints";
import {userMixin} from "@/mixins/userMixin";
import EventBus from "@/Events/EventBus";
import {clientEvents} from "@/Events/clientEventEnums";
import {alertTypes} from "@/Enums";
export default {
  name: "profileQuickMenu",
  components: {AddProfilePictureForm},
  mixins: [userMixin],
  data() {
    return {
      showChangeProfilePictureForm: false
    }
  },
  methods: {
    toggleShowChangeProfilePictureForm() {
      this.showChangeProfilePictureForm = !this.showChangeProfilePictureForm
    },
    uploadProfilePicture(image) {
      this.toggleShowChangeProfilePictureForm()
      let formData = convertImage(image)
      uploadImage(APIEndpoints.uploadProfilePicture, formData). then(response => {
        this.setUser(response)
        EventBus.emit(clientEvents.ALERT_EVENT, "Profile picture changed!", alertTypes.SUCCESS)
      })
    },
    showSettings() {
      EventBus.emit(clientEvents.SHOW_SETTINGS)
    },
    signOut() {
      //signOut(this.$cookies.get('authToken'), () => {})
      this.$cookies.remove('authToken')
      location.reload()
    },
  }
}
</script>

<style scoped>
@import "@/styles/PagesStyles/MainPageStyle.css";

.quick-menu-list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.list-row {
  display: flex;
  align-items: center;
  column-gap: 15px;
  padding: 5px;
  cursor: pointer;
}

.list-row:hover {
  background-color: var(--profile-quick-setings-background-hover);
}

.list-item {
  white-space: nowrap;
}


</style>