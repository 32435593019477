import { mapState, mapMutations } from 'vuex';

export const themeMixin = {
    computed: {
        ...mapState({
            isDarkmode: state => state.theme.isDarkmode,
        }),
    },
    methods: {
        ...mapMutations('theme', ['toggleDarkmode', 'setDarkmode', 'saveTheme', 'checkTheme', 'saveTheme'])
}
};