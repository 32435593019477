import { mapState, mapMutations } from 'vuex';

export const formMixin = {
        data() {
            return {
                showForm: false
            }
        },
    methods: {
       toggleShowForm() {
           this.showForm = !this.showForm
       }
}
};