<template>
  <section id="auth-wrapper">
    <pop-up-alert-component ref="alert-component"/>
    <img :src="require(`@/assets/StudioMerkas_Logo.png`)" id="studio-merkas-logo">
    <div id="auth-content">
      <router-view id="login-router-view"/>
    </div>
  </section>
</template>

<script>
import * as Events from "events";
import EventBus from "@/Events/EventBus";
import {clientEvents} from "@/Events/clientEventEnums";
import PopUpAlertComponent from "@/components/miniComponents/alerts/popUpAlertComponent";
export default {
  name: "authComponent",
  components: {PopUpAlertComponent},
  mounted() {
  }
}
</script>

<style scoped>
@import "@/styles/PagesStyles/loginStyle.css";

  #auth-wrapper {
    min-width: 100vw;
    min-height: 100vh;
    background-color: var(--login-background);
  }

  #studio-merkas-logo {
    width: 75px;
    height: auto;
    position: fixed;
    top: 10px;
    left: 10px;
  }

  #auth-content {
    width: auto;
    overflow: scroll;
    max-height: calc(100vh - 110px);
    height: fit-content;
  }

  #login-router-view {
    width: 100%;
    height: 100%;
  }
</style>