import APIEndpoints from "@/Scripts/API/Services/APIEndpoints";
import VueCookies from 'vue-cookies'
import $cookies from "core-js/internals/task";
import {routeTo} from "@/Router";
import EventBus from "@/Events/EventBus";
import {clientEvents} from "@/Events/clientEventEnums";
import {alertTypes} from "@/Enums";


export function signIn(password, email, callBack) {
    APIEndpoints.signIn(password, email).then(response => {
        if (response.status === 200) {
            callBack(response.data['token'])
        }
    }).catch(err => {
        EventBus.emit(clientEvents.ALERT_EVENT,  err.response.data, alertTypes.INFORMATION)
    })
}

export function signUp(data) {
    APIEndpoints.signUp(data).then(response => {
        if (response.status === 200) {
            EventBus.emit(clientEvents.ALERT_EVENT, response.data, alertTypes.INFORMATION)
        }
    }).catch(err => {
        EventBus.emit(clientEvents.ALERT_EVENT, err.response.data, alertTypes.INFORMATION)
    })
}

export function checkLoginPermission(email, callBack) {
    APIEndpoints.checkLoginPermission(email).then(response => {
        if (response.status == 200) {
            callBack()
        }
    }).catch(err => {
        alert("You don't have the permission to log in!")
    })
}

export function signOut(token, callback) {
    APIEndpoints.signOut(token).then(response => {
        let status = response.status
        switch (status) {
            case 204: {
                callback()
                break
            }
            default: {
                routeTo('Authentication')
                break
            }
        }
    }).catch(err => {
        routeTo('Authentication')
    })
}