<template>
  <div/>
</template>

<script>
import {openSocketConnection, receiveSocketMessage} from "@/Scripts/API/Sockets";
import {mapMutations} from 'vuex';
import VueCookies from 'vue-cookies';
import {userMixin} from "@/mixins/userMixin";
import {invitesMixin} from "@/mixins/invitesMixin";
import EventBus from "@/Events/EventBus";
import {clientEvents} from "@/Events/clientEventEnums";
import {notificationsMixin} from "@/mixins/notificationsMixin";
import {alertTypes} from "@/Enums";

export default {
  name: "socketComponent",
  mixins: [userMixin, invitesMixin, notificationsMixin],
  data() {
    return {
      connection: null,
      socketEvents: {
        NEW_INVITE:  "NEW_INVITE",
        NEW_NOTIFICATION: "NEW_NOTIFICATION",
        ACCOUNT_VERIFIED: "ACCOUNT_VERIFIED"
      }
    }
  },
  mounted() {
      openSocketConnection(VueCookies.get('authToken'), (connection) => {
        this.connection = connection
        console.log('connection', this.connection)
        //this.setConnection(connection)
      })

      receiveSocketMessage(this.connection, async (response) => {
        const event = response['event']
        const data = response['data']

          switch (event) {
            case this.socketEvents.NEW_INVITE: {
              EventBus.emit(clientEvents.ALERT_EVENT, `${data['inviting_user'].username} invited you to ${data['studio'].name}`, alertTypes.INFORMATION)
              const newInvitesCount = this.newInvitesCount + 1
              this.setNewInvitesCount(newInvitesCount)
              this.addInvite(data)
              break
            }
            case this.socketEvents.NEW_NOTIFICATION: {
              EventBus.emit(clientEvents.ALERT_EVENT, `You've got a new notification!`, alertTypes.INFORMATION)
              const newNotificationsCount = this.newNotifications + 1
              this.setNewNotificationsCount(newNotificationsCount)
              break
            }
            case this.socketEvents.ACCOUNT_VERIFIED: {
              EventBus.emit(clientEvents.ALERT_EVENT, `Your account is verified now!`, alertTypes.INFORMATION)
              const newNotificationsCount = this.newNotifications + 1
              this.setNewNotificationsCount(newNotificationsCount)
              this.user['is_verified'] = true
              break
            }
          }
      })
  },
  methods: {
    ...mapMutations('studio', ['setStudio', 'setConnection', 'setIsLoading', 'setContractTypes', "setFeedbackTypes"]),
  }
}
</script>

<style scoped>

</style>