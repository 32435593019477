<template>
  <div class="notification-entry-wrapper">
    <div class="date-wrapper">
      <p class="text default">{{getDate(entry.date)}}</p>
    </div>
    <div class="notification-entries">
      <div v-for="notification in internalEntry.notifications" :key="notification">
        <div class="entry-wrapper">
          <div class="entry-content-wrapper">
            <div class="time-wrapper cursor-default">
              <!--
              <p class="text default">{{getTime(notification.timestamp)}}</p>
              -->
              <p class="text default highlighted" v-if="notification.studio">{{notification.studio.name}}</p>
            </div>
            <div>
              <p class="text default text-wrapper" @click="clickNotificationRoute(notification)" :class="{'cursor-pointer': notification['route'],'text-url': notification['route'], 'cursor-default': !notification['route']}">{{notification.text}}</p>
            </div>
          </div>
          <span class="material-symbols-outlined close-icon" @click="deleteNotification(notification.id)">close</span>
        </div>
      </div>
    </div>
  </div>
  <insurance-component :text="insuranceText" v-if="showForm" @closeForm="toggleShowForm" @submit="handleInsuranceSubmit"/>
</template>

<script>
import {getTodayDDMMYYYY, okr_frontend_url} from "@/Utils";
import {performPostRequest, performRequestWithQuery} from "@/Scripts/API/Services/APIService";
import APIEndpoints from "@/Scripts/API/Services/APIEndpoints";
import InsuranceComponent from "@/components/miniComponents/InsuranceComponent";
import {studioMixin} from "@/mixins/studioMixin";
import {formMixin} from "@/mixins/formMixin";
import {alertTypes} from "@/Enums";
import {clientEvents} from "@/Events/clientEventEnums";
import EventBus from "@/Events/EventBus";
export default {
  name: "NotificationModule",
  components: {InsuranceComponent},
  props: {
    entry: {}
  },
  mixins: [studioMixin, formMixin],
  data() {
    return {
      internalEntry: this.entry,
      time: '',
      clickedNotification: null
    }
  },
  computed: {
    getDate: function () {
      return (date) => {
        if (date === getTodayDDMMYYYY()) {
          return "Today"
        } else {
          return date
        }
      }
    },
    getTime: function () {
      return (timestamp) => {
        const dateObject = new Date(timestamp);

// Extrahiere Stunden und Minuten aus dem Date-Objekt
        const hours = dateObject.getHours();
        const minutes = dateObject.getMinutes();
        return `${hours}:${minutes}`
      }

    },
    insuranceText: function () {
      return `This notification will re-route you to "${this.clickedNotification.studio.name}". Are you sure?`
    }
  },
  mounted() {
    performRequestWithQuery(APIEndpoints.getNotificationsForDate, this.internalEntry.date).then(data => {
      this.internalEntry.notifications = data
    })
  },
  methods: {
    deleteNotification(id) {
      console.log(id)
      performRequestWithQuery(APIEndpoints.deleteNotifications, id).then(() => {
        this.internalEntry.notifications = this.internalEntry.notifications.filter(notification => {
          return notification.id !== id
        })

        if (this.internalEntry.notifications.length === 0) this.$emit("removeEntry", this.internalEntry.id)

        EventBus.emit(clientEvents.ALERT_EVENT, "Notification deleted!", alertTypes.SUCCESS)
      })
    },
    toggleOpen() {
      this.isOpened = !this.isOpened
      if (this.isOpened) {
        performRequestWithQuery(APIEndpoints.getNotificationsForDate, this.internalEntry.date).then(data => {
          this.internalEntry.notifications = data
        })
      }
    },
    checkIsDateToday(date) {

// Erstellen des heutigen Datums als String im gleichen Format wie der gegebene String
      var todayDateString = new Date().toLocaleDateString('de-DE', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric'
      });

// Überprüfen, ob der gegebene String das heutige Datum repräsentiert
      if (date === todayDateString) {
        return true
      }
      return false
    },
    clickNotificationRoute(notification) {
      /**
       * Diese Methoden handled das klicken auf eine Notification.
       * Wenn die Notification eine route hat, soll der User in das studio der route eingelogt werden.
       * Wenn route === null oder route.studio === null dann abbruch.
       * Wenn route gegeben:
       * Wenn Ziel der Route in aktuellem studio, dann einfacher reroute durch klick.
       * Wenn Ziel in einem anderen studio, dann PopUp zum Nachfragen ob der User in das andere studio will -> login -> reroute
       * **/
      this.clickedNotification = notification
      if (!notification['route'] || !notification['studio']) return

      this.toggleShowForm()
    },
    handleInsuranceSubmit() {
      /*
      * Diese methode wird aufgerufen, wenn der User sich über die notification in ein studio einloggen möchte, in dem er auf eine notification klickt.
      * Ablauf: User in studio einloggen -> Bei erfolg reroute zu notification route
      * **/
      console.log('handle submit', this.clickedNotification.studio.id)
      performPostRequest(APIEndpoints.signIntoStudio, {"studio_id": this.clickedNotification.studio.id}).then(studioToken => {
        this.$cookies.set('studioToken', studioToken, 100000) // StudioToken setzen, um neues studio zu laden
        window.location.href = `${okr_frontend_url}${this.clickedNotification['route']}`
        // window.location.href = `${okr_frontend_url}/authenticate/${this.$cookies.get('authToken')}`
      }).catch(err => {
        console.error(err)
      })

    },
  }
}

</script>

<style scoped>


.cursor-pointer {
  cursor: pointer;
}

.cursor-default {
  cursor: default;
}

.date-wrapper, .time-wrapper {
  height: 32px;
  display: flex;
  align-items: center;
  width: 100%;
  column-gap: 10px;
}

.text-url:hover {
  background-color: var(--highlight-color-primary);
  color: white;
}

.date-wrapper {
  cursor: pointer;
}

.notification-entries {
  display: flex;
  flex-direction: column;
  height: fit-content;
  row-gap: 15px;
}

.text-wrapper {
  padding-block: 3px;
}

.text-url {

}

.date-wrapper:hover {
  background-color: var(--notification-hover-color);
}

.notification-entry-wrapper {
  height: fit-content;
}


p {
  margin: 0;
  white-space: pre-wrap;
}

.entry-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--section-background-color);
  padding: 5px;
}
</style>