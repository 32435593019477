import {themes} from "@/Enums";
import store from "@/stores/Store";

export default {
    namespaced: true,
    state: {
        isDarkmode: true,
    },
    mutations: {
        toggleDarkmode(state) {
            state.isDarkmode = !state.isDarkmode;
            if (state.isDarkmode) localStorage.setItem('theme', 'dark')
            else localStorage.setItem('theme', 'light')
            document.documentElement.setAttribute('darkmode', state.isDarkmode.toString())
        },
        setDarkmode(state, payload) {
            state.isDarkmode = payload
        },
        saveTheme(state, payload) {
            localStorage.setItem('theme', payload)
                switch (payload) {
                case themes.DARK_MODE.value:
                    state.isDarkmode = true
                    break;
                case themes.LIGHT_MODE.value:
                    state.isDarkmode = false
                    break;
                case themes.SYSTEM.value:
                    state.isDarkmode = window.matchMedia('(prefers-color-scheme: dark)').matches
                    window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', handleDarkModePreferenceChange)
                    break;
            }
            setThemeAttribute(state.isDarkmode)
        },
        checkTheme(state) {
            let current = localStorage.getItem('theme')
            if (!current) {
                current = themes.SYSTEM.value
                state.isDarkmode = true
                localStorage.setItem('theme', current)
            }
        }
    },
}

function setThemeAttribute(isDarkmode) {
    document.documentElement.setAttribute('darkmode', isDarkmode.toString())
}


function handleDarkModePreferenceChange(event) {
    if (localStorage.getItem('theme') === themes.SYSTEM.value) {
        store.commit('theme/setDarkmode', event.matches)
        setThemeAttribute(event.matches)
    }
}
