<template>
  <div class="invite-wrapper">
    <div class="info-wrapper">
      <img class="studio-image" :src="baseMediaUrl + invite['studio']['image_url']">
      <div class="text-wrapper">
        <p class="text inviting-user">{{invite["inviting_user"]["username"]}} invited you to</p>
        <h3 class="text font-weight-normal bigger">{{invite["studio"]["name"]}}</h3>
      </div>

    </div>
    <div class="buttons-wrapper">
      <button class="accept-button text material-symbols-outlined" @click="clickAccept">check</button>
      <button class="deny-button text material-symbols-outlined" @click="clickDeny">close</button>
    </div>
  </div>

</template>

<script>
import {baseMediaUrl} from "@/Utils";
import {signIntoStudio} from "@/Scripts/API/StudioAPI";
export default {
  name: "invitesListModule",
  props: {
    invite: {}
  },
  data() {
    return {
      baseMediaUrl,
    }
  },
  methods: {
    clickDeny() {
      this.$emit('denyInvite', this.invite)
    },
    clickAccept() {
      this.$emit('acceptInvite', this.invite)
    }
  }
}
</script>

<style scoped>
  .info-wrapper {
    display: flex;
    align-items: center;
    column-gap: 15px;
    height: 100%;
    min-width: fit-content;
    max-width: fit-content;
  }

  .invite-wrapper {
    height: 100px;
    padding: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: var(--card-background-color-main);
    position: relative;
    border-radius: var(--border-radius);
  }

  .invite-wrapper:hover {
    background-color: var(--card-background-color-main-hover);

  }

  .studio-image {
    height: 100%;
    aspect-ratio: 1/1;
    border-radius: var(--border-radius);
  }

  .accept-button, .deny-button {
    width: 40px;
    height: 40px;
    background-color: var(--highlight-color-primary);
    border: none;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 25px;
    cursor: pointer;

    font-variation-settings:
        'FILL' 0,
        'wght' 400,
        'GRAD' 0,
        'opsz' 24
  }

  .text-wrapper {
    display: flex;
    flex-direction: column;
    row-gap: 5px;
  }

  .inviting-user {
    opacity: 0.4;
  }

  .deny-button {
    color: var(--icon-color-light);
    background: none;
  }

  .setting-icon {
    right: 10px;
    top: 10px;
    position: absolute;
  }

  .buttons-wrapper {
    width: fit-content;
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 5px;
    padding: 0;
  }


</style>
