<template>
  <div class="form-background">
  <CircleSpinner/>
    <loading-text-component/>
  </div>
</template>

<script>
import CircleSpinner from "@/components/miniComponents/UIComponents/Loading/Spinners/CircleSpinner";
import LoadingTextComponent from "@/components/miniComponents/UIComponents/Loading/LoadingTextComponent";
export default {
  name: "LoadingComponent",
  components: {LoadingTextComponent, CircleSpinner},
}
</script>

<style scoped>
@import "/src/styles/FormStyle.css";
#text {
  color: var(--highlight-color-primary);
  margin-left: 10px;
  font-weight: bold;
}


</style>