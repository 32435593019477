<template>
  <div class="form-background" :style="externalStyle" @click="clickBackground">
    <slot name="form"></slot>
  </div>
</template>

<script>
import EventBus from "@/Events/EventBus";
import {clientEvents} from "@/Events/clientEventEnums";

export default {
  name: "formBackground",
  props: {
    externalStyle: {
      type: Object,
      default: () => ({})
    }
  }, methods: {
    clickBackground(event) {
      const classList = event.target.classList
      if (classList.contains('form-background')) EventBus.emit(clientEvents.CLICKED_FORM_BACKGROUND)
    }
  }
}
</script>

<style scoped>

.form-background {
  width: 100vw;
  min-height: calc(100vh - 80px);
  left: 0;
  position: fixed;
  top: 80px;
  background: rgba(31, 31, 36, 0.2);
  backdrop-filter: blur(37px);
  display: flex;
  animation-duration: 2s;
  z-index: 20;
  align-items: center;
  justify-content: center;
  overflow: scroll;
}


@media (min-width: 751px) {
  .form-background {
    position: fixed;
    top: 0;
    width: calc(100vw - 200px);
    right: 0;
    left: 200px;
    min-height: 100vh;
  }

}

</style>