<template>
  <main-component-wrapper>
    Feedback
    <template #content>
      <div id="feedback-content-container">
        <div id="feedback-content">
          <div class="textbox">
            <p class="text">Thank you for choosing our OKR-tool! Your decision to be part of our growing community means a lot to us, and we truly value your commitment to making our app a valuable project management tool.</p>
            <p class="text">Your feedback is vital in shaping the future of our app and ensuring it meets your specific requirements. We kindly request a few moments of your time to share your thoughts on usability, design, functionality, stability, security, customer support, and any other general feedback.</p>
            <p class="text">Your input will help us tailor the app to your preferences and improve the user experience. Please click the button below to fill out our feedback form. Thank you for your support!</p>
          </div>
          <div class="textbox">
            <p class="text">
              Your suggestions and improvement ideas will help us tailor the app to your preferences.
              Please fill out our feedback form below.
              Thank you for your support!</p>
            <p class="text" >Best regards,<br>
              Studio Merkas
            </p>
          </div>
          <div id="feedbacks-container">
            <div id="feedbacks-scroll-container">
              <div id="feedbacks-top-bar">
                <add-button :title="'New feedback'" @buttonAction="toggleShowForm"/>
              </div>

              <feedback-module v-for="(feedback, index) in feedBacks" :key="feedback.id" :feed-back="feedback" :index="index" @deleteFeedback="deleteFeedback"></feedback-module>

            </div>
          </div>
          </div>

      </div>
    </template>
    <feedback-form v-if="showForm" @closeForm="toggleShowForm" @submit="submitFeedback"/>
  </main-component-wrapper>
</template>

<script>
import MainComponentWrapper from "@/components/MainComponentWrapper";
import AddButton from "@/components/miniComponents/UIComponents/Buttons/AddButton";
import FeedbackForm from "@/components/feedback/forms/feedbackForm";
import {formMixin} from "@/mixins/formMixin";
import {performPostRequest, performRequest, performRequestWithQuery} from "@/Scripts/API/Services/APIService";
import APIEndpoints from "@/Scripts/API/Services/APIEndpoints";
import EventBus from "@/Events/EventBus";
import {clientEvents} from "@/Events/clientEventEnums";
import {alertTypes} from "@/Enums";
import FeedbackModule from "@/components/feedback/modules/feedbackModule";
import {userMixin} from "@/mixins/userMixin";
export default {
  name: "feedbackComponent",
  components: {FeedbackModule, FeedbackForm, AddButton, MainComponentWrapper},
  mixins: [formMixin, userMixin],
  data() {
    return {
      feedBacks: []
    }
  },
  async mounted() {
    this.feedBacks = await performRequestWithQuery(APIEndpoints.getFeedBacks)
  },
  methods: {
    submitFeedback(feedbackObject) {
     performPostRequest(APIEndpoints.submitFeedback, feedbackObject).then(response => {
       EventBus.emit(clientEvents.ALERT_EVENT, "Feedback submitted", alertTypes.SUCCESS)
        this.feedBacks.unshift(response)
     })
    },
    deleteFeedback(feedbackId) {
      performRequestWithQuery(APIEndpoints.deleteFeedback, feedbackId).then(() => {
        EventBus.emit(clientEvents.ALERT_EVENT, "Feedback deleted", alertTypes.SUCCESS)
        this.feedBacks = this.feedBacks.filter(feedback => {return feedback.id !== feedbackId})
      })
    }
  },
}
</script>

<style scoped>

#feedback-content-container {
  flex: 1;
}

#feedback-content {
  max-height: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

  .textbox {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
  }

  #feedbacks-container {
    flex: 1;
  }

  #feedbacks-scroll-container {
    max-height: 100%;
    height: fit-content;
    overflow: scroll;
    display: flex;
    flex-direction: column;
    row-gap: 20px;
  }

  #feedbacks-top-bar {
    width: auto;
    height: fit-content;
    display: flex;
    justify-content: flex-end;
    padding-inline: 10px;
  }
</style>