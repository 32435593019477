<template>
  <div class="studio-wrapper" @click="signIntoStudio($event.target)">
    <img class="studio-image" :src="baseMediaUrl + internalStudio['image_url']">
    <h3 class="text font-weight-normal font-weight-normal page-heading-size">{{internalStudio['name']}}</h3>
    <span class="material-symbols-outlined icon setting-icon" @click="toggleShowForm">settings</span>
    <!--<button class="sign-in-button text" @click="signIntoStudio">Sign in</button>-->
  </div>
  <studio-form v-if="showForm" @closeForm="toggleShowForm" :studio="internalStudio" @updateStudio="updateStudio" @leaveStudio="leaveStudio"/>
</template>

<script>
import {baseMediaUrl} from "@/Utils";
import {signIntoStudio} from "@/Scripts/API/StudioAPI";
import StudioForm from "@/components/studios/forms/studioForm";
import {formMixin} from "@/mixins/formMixin";
export default {
  name: "studioListModule",
  components: {StudioForm},
  props: {
    studio: {}
  },
  mixins: [formMixin],
  emits: ['leaveStudio'],
  data() {
    return {
      baseMediaUrl,
      internalStudio: this.studio
    }
  },
  methods: {
    signIntoStudio(element) {
      console.log('element: ', element)
      if (!element.classList.contains('icon')) {
        const object = {
          "studio_id": this.studio.id
        }
        signIntoStudio( this.$cookies.get('authToken'), object)
      }

    },
    updateStudio(studio) {
      this.internalStudio = studio
    },
    leaveStudio(id) {
      this.$emit('leaveStudio', id)
    }
  }
}
</script>

<style scoped>
  .studio-wrapper {
    height: 100px;
    padding: 10px;
    display: flex;
    align-items: center;
    column-gap: 15px;
    background-color: var(--card-background-color-main);
    position: relative;
    border-radius: var(--border-radius);
    cursor: pointer;
  }

  .studio-wrapper:hover {
    background-color: var(--card-background-color-main-hover);

  }

  .studio-image {
    height: 100%;
    aspect-ratio: 1/1;
    border-radius: var(--border-radius);
    object-fit: cover;
  }

  .sign-in-button {
    position: absolute;
    right: 10px;
    bottom: 10px;
    cursor: pointer;
    border: none;
    background-color: var(--highlight-color-primary);
    color: #eaeaea;
    padding-inline: 20px;
    padding-block: 10px;
    border-radius: var(--border-radius);
  }

  .setting-icon {
    right: 10px;
    top: 10px;
    position: absolute;
  }


</style>