import axios from "axios";
import {baseApiUrl} from "@/Utils";
import VueCookies from 'vue-cookies';
import {routeTo} from "@/Router";

//export default (token, url = `http://${baseApiUrl}`) => {
export default (token, url = `${baseApiUrl}/`) => {

    let tokenString = undefined
    if (token) tokenString = `Token ${token}`
    return axios.create({
        baseURL: url,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': tokenString,
        }
    })
}


export async function Request(endpoint) {
    console.log('performing get request')
    const authToken = VueCookies.get("authToken");
    const studioToken = VueCookies.get("studioToken");
    console.log(authToken, studioToken)
    return new Promise((resolve, reject) => {
        endpoint(authToken, studioToken)
            .then(response => {
                resolve(response.data); // Erfolgreiche Antwort wird weitergegeben
            })
            .catch(error => {
                switch (error.response.status) {
                    case 401: {
                        routeTo('Authentication')
                        break
                    }
                    default: {
                        reject(error); // Fehler wird weitergegeben
                    }
                }
            });
    });
}

export async function performRequest(endpoint) {
    console.log('performing get request')
    const authToken = VueCookies.get("authToken");
    const studioToken = VueCookies.get("studioToken");
    console.log(authToken, studioToken)
    return new Promise((resolve, reject) => {
        endpoint(authToken, studioToken)
            .then(response => {
                resolve(response.data); // Erfolgreiche Antwort wird weitergegeben
            })
            .catch(error => {
                switch (error.response.status) {
                    case 401: {
                        routeTo('Authentication')
                        break
                    }
                    default: {
                        reject(error); // Fehler wird weitergegeben
                    }
                }
            });
    });
}

export async function performRequestWithoutToken(endpoint, queryData) {
    return new Promise((resolve, reject) => {
        endpoint(queryData)
            .then(response => {
                resolve(response.data); // Erfolgreiche Antwort wird weitergegeben
            })
            .catch(error => {
                switch (error.response.status) {
                    case 401: {
                        routeTo('Authentication')
                        break
                    }
                    default: {
                        reject(error); // Fehler wird weitergegeben
                    }
                }
            });
    });
}

export function performRequestWithQuery(endpoint, queryData) {
    console.log('performing get request')
    const authToken = VueCookies.get("authToken");
    return new Promise((resolve, reject) => {
        endpoint(authToken, queryData)
            .then(response => {
                resolve(response.data); // Erfolgreiche Antwort wird weitergegeben
            })
            .catch(error => {
                switch (error.response.status) {
                    case 401: {
                        routeTo('Authentication')
                        break
                    }
                    default: {
                        reject(error); // Fehler wird weitergegeben
                    }
                }
            });
    });
}

export function performRequestWithQueryFullResponse(endpoint, queryData) {
    const authToken = VueCookies.get("authToken");
    return new Promise((resolve, reject) => {
        endpoint(authToken, queryData)
            .then(response => {
                resolve(response); // Erfolgreiche Antwort wird weitergegeben
            })
            .catch(error => {
                switch (error.response.status) {
                    case 401: {
                        routeTo('Authentication')
                        break
                    }
                    default: {
                        reject(error); // Fehler wird weitergegeben
                    }
                }
            });
    });
}

export async function performPostRequest(endpoint, data) {
    const authToken = VueCookies.get("authToken");
    return new Promise((resolve, reject) => {
        endpoint(authToken, data)
            .then(response => {
                resolve(response.data); // Erfolgreiche Antwort wird weitergegeben
            })
            .catch(error => {
                switch (error.response.status) {
                    case 401: {
                        routeTo('Authentication')
                        break
                    }
                    default: {
                        reject(error); // Fehler wird weitergegeben
                    }
                }
            });
    });
}

export async function POSTRequestWIthQuery(endpoint, queryParameter, data) {
    console.log('performing get request')
    const authToken = VueCookies.get("authToken");
    const studioToken = VueCookies.get("studioToken");
    console.log(authToken, studioToken)
    return new Promise((resolve, reject) => {
        endpoint(authToken, studioToken, queryParameter, data)
            .then(response => {
                resolve(response.data); // Erfolgreiche Antwort wird weitergegeben
            })
            .catch(error => {
                switch (error.response.status) {
                    case 401: {
                        routeTo('Authentication')
                        break
                    }
                    default: {
                        reject(error); // Fehler wird weitergegeben
                    }
                }
            });
    });
}

export function RequestWithQuery(endpoint, queryData) {
    console.log('performing get request')
    const authToken = VueCookies.get("authToken");
    const studioToken = VueCookies.get("studioToken");
    console.log(authToken, studioToken)
    return new Promise((resolve, reject) => {
        endpoint(authToken, studioToken, queryData)
            .then(response => {
                resolve(response.data); // Erfolgreiche Antwort wird weitergegeben
            })
            .catch(error => {
                switch (error.response.status) {
                    case 401: {
                        routeTo('Authentication')
                        break
                    }
                    default: {
                        reject(error); // Fehler wird weitergegeben
                    }
                }
            });
    });
}


export function uploadImage(endpoint, formData) {
    const authToken = VueCookies.get("authToken");

    return new Promise((resolve, reject) => {
        endpoint(authToken, formData)
            .then(response => {
                resolve(response.data); // Erfolgreiche Antwort wird weitergegeben
            })
            .catch(error => {
                switch (error.response.status) {
                    case 401: {
                        routeTo('Authentication')
                        break
                    }
                    default: {
                        reject(error); // Fehler wird weitergegeben
                    }
                }
            });
    });
}