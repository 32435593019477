<template>
  <div class="bar-wrapper">
    <div class="bar-wrapper-content-left">
      <searchbar-component/>
    </div>
    <div class="bar-wrapper-content-right">
    </div>
  </div>
</template>

<script>
import {mapGetters, mapState,mapMutations} from 'vuex';
import SearchbarComponent from "@/components/miniComponents/UIComponents/Searchbar";
import AddButton from "@/components/miniComponents/UIComponents/Buttons/AddButton";

export default {
  name: "InvitesOptionBar",
  components: {
    SearchbarComponent,},

  data() {
    return {
    }
  },
  mounted() {

  },
  computed: {
    ...mapState({
      isDarkmode: state => state.theme.isDarkmode,
    }),
    ...mapGetters("user", ['getUser', 'getUserRole']),
    ...mapGetters("objectives", ['getCurrentFilter'])

  },
  methods: {
    ...mapMutations('objectives', ['setCurrentFilter']),
  }

}
</script>

<style>
@import "/src/styles/OptionsbarStyle.css";

.bar-wrapper-content-right {
  justify-content: right;
}
#year-picker {
  display: flex;
  align-items: center;
}

.bar-wrapper-content-left {
  justify-content: flex-start;
}

#year-decrease, #year-increase {
  color: var(--highlight-color-primary);
  font-size: 32px;
  cursor: pointer;
  font-variation-settings:
      'FILL' 0,
      'wght' 400,
      'GRAD' 200,
      'opsz' 48
}

#current-year {
  font-size: 1em;
}
/*


@media (min-width: 220px) and (max-width: 609px) {
  .bar-wrapper {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    margin-left: 10px;
  }
  .bar-wrapper-content-left {
    width: 100%;
    justify-content: space-between;
  }
  .bar-wrapper-content-right {
    width: 100%;
    margin-top: 10px;
    justify-content: space-between;
  }
  #view-mode-button {
    margin-left: 0;
  }

}

@media (min-width: 610px) and (max-width: 1014px) {
  #view-mode-button {
    margin-left: 0;
  }
}*/
</style>