<template>
  <form-background>
    <template #form>
      <form-wrapper :heading="'Change profile picture'" :button-options="buttonOptions" @clickSubmit="submit" :amount-content-screens="1" @closeForm="$emit('closeForm')">
       <template #contentBox0>
         <form-row :title="'Image'" :type="'image'" v-model="image"></form-row>
       </template>
      </form-wrapper>
    </template>
  </form-background>
</template>

<script>
import FormBackground from "@/components/formComponents/formBackground";
import FormWrapper from "@/components/formComponents/formWrapper";
import FormRow from "@/components/formComponents/formRow";
import EventBus from "@/Events/EventBus";
import {clientEvents} from "@/Events/clientEventEnums";
import {maxImageUploadSize} from "@/Utils";
import {alertTypes} from "@/Enums";
export default {
  name: "addProfilePictureForm",
  components: {FormWrapper, FormRow, FormBackground},
  emits: ['closeForm'],
  data() {
    return {
      buttonOptions: {
        "hasSubmit": true,
        "hasDelete": false,
      },
      image: null,
      maxImageUploadSize
    }
  },
  mounted() {
    EventBus.on(clientEvents.CLICKED_FORM_BACKGROUND, () => {
      this.$emit('closeForm')
    })
    },
  methods: {
    submit() {
      if(this.image === null) {
        EventBus.emit(clientEvents.ALERT_EVENT, 'Please select an image.')
        return;
      }
      else if(this.getImageSizeMb(this.image) > this.maxImageUploadSize) {

        EventBus.emit(clientEvents.ALERT_EVENT, 'Oops... It seems that your image is too big.', alertTypes.INFORMATION)
        return;
      }

      this.$emit('uploadProfilePicture', this.image)

    },
    getImageSizeMb(image) {
      if (!image) return 0
      return (image.size / (1024 * 1024)).toFixed(1); // auf 2 Dezimalstellen gerundet
    },
  }
}
</script>

<style scoped>

</style>