<template>
  <div class="info-button-wrapper" @mouseover="setShowInformation(true)" @mouseleave="setShowInformation(false)">
    <p class="material-symbols-outlined info-icon" :class="{warning: isWarning}">info</p>
    <div class="information-wrapper" v-if="showInformation">
      <p class="text default info-text" v-for="text in texts" :key="text">{{text}}</p>
      <button class="info-action-button text" v-if="hasButton" @click.prevent="$emit('buttonAction')">{{buttonText}}</button>
    </div>
  </div>
</template>

<script>
export default {
  name: "infoButton",
  props: {
    isWarning: {},
    texts: {},
    buttonText: {},
    hasButton: {}
  },
  emits: ['buttonAction'],
  data() {
    return {
      showInformation: false
    }
  },
  methods: {
    setShowInformation(bool) {
      console.log('hover')
      this.showInformation = bool
    }
  }
}
</script>

<style scoped>

.info-text {
  width: 100%;
  text-align: center;
}

.info-button-wrapper {
  width: fit-content;
  height: fit-content;
  position: relative;
}

.info-icon {
  margin: 0;
  color: var(--highlight-color-primary);
  cursor: pointer;
}

.warning {
  color: var(--highlight-color-cancel);
}

.information-wrapper {
  width: 100px;
  height: 100px;
  position: absolute;
  z-index: 10000000;
}

.information-wrapper {
  width: 200px;
  height: fit-content;
  position: absolute;
  padding: 15px;
  z-index: 100000000;
  left: -95px;
  border-radius: var(--border-radius);
  background-color: var(--formcard-background-tertiary);
  box-shadow: var(--notifications-shadow);
  display: flex;
  flex-direction: column;

}

.info-action-button {
  width: 100%;
  border: None;
  border-radius: 3px;
  background-color: var(--highlight-color-primary);
  justify-content: center;
  display: flex;
  margin-top: 20px;
  padding-block: 5px;
  cursor: pointer;
  color: #eaeaea;
}

</style>