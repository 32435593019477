<template>
  <div class="dropdown custom-select input-element">
    <div id="values">
      <span class="text" v-if="internalSelectedOptions.length === 0" style="font-size: 14px">{{placeholder}}</span>
      <TagComponent :value-field="valueField" :can-delete-tag="internalIsEditable" v-for="selected in internalSelectedOptions" :key="selected.id" :value="selected" @remove="removeFromSelected(selected)"></TagComponent>
    </div>
    <span ref="icon" class="material-symbols-outlined icon" @click="toggleDropdown(); toggleIcon()">keyboard_arrow_down</span>
    <div ref="myDropdown" id="myDropdown" class="content" :class="{'locked': !internalIsEditable}">
      <div id="search-wrapper" :class="{darkmode: isDarkmode, lightmode: !isDarkmode}">
        <span id="search-icon" class="material-symbols-outlined"> search </span>
        <input id="input"  v-model="searchText" @input="searchEntry(searchText)" placeholder="Search..." type="search">
      </div>
      <section v-if="isInSections">
        <div id="section" v-for="(section, index) in filteredOptions" :key="section.heading">
          <div class="section-header">
            <span  :class="{'invisible': section['options'].length === 0}" class="material-symbols-outlined arrow-icon icon" :id="'arrow-icon' + index" @click="toggleSection(index)">keyboard_arrow_down</span>
            <h3 class="text default section-heading font-weight-normal">{{section.heading}}</h3>
          </div>
          <div class="section-content" :ref="'sectionContent' + index">
            <div id="value" v-for="v in section.options" :key="v.id">
              <input :id="'checkbox' + v['id']"  type="checkbox"  @click="removeFromSelected(v)" name="checkbox" class="checkbox" v-if="isItemSelected(v.id)" checked :disabled="!internalIsEditable">
              <input :id="'checkbox' + v['id']"  type="checkbox"  @click="addToSelected(v)" name="checkbox" class="checkbox" v-else :disabled="!internalIsEditable">
              <label for="checkbox" class="text" :class="{'locked': !internalIsEditable}">{{v[valueField]}}</label>
            </div>
          </div>
        </div>
      </section>
      <section v-else>
        <div id="value" v-for="v in filteredOptions" :key="v.id">
          <input :id="'checkbox' + v['id']"  type="checkbox"  @click="removeFromSelected(v)" name="checkbox" class="checkbox" v-if="isItemSelected(v.id)" checked :disabled="!internalIsEditable">
          <input :id="'checkbox' + v['id']"  type="checkbox"  @click="addToSelected(v)" name="checkbox" class="checkbox" v-else :disabled="!internalIsEditable">
          <label for="checkbox" class="text" :class="{'locked': !internalIsEditable}">{{v[valueField]}}</label>
        </div>
      </section>

    </div>
  </div>

</template>
<script>
import TagComponent from "@/components/miniComponents/UIComponents/TagComponent";
import {mapState} from "vuex";
import {watch} from "vue";
import SearchbarComponent from "@/components/miniComponents/UIComponents/Searchbar";

export default  {
  name: 'CustomMultiselect',
  components: {TagComponent},
  props: {
    options: {
      default: []
    },
    isInSections: {
      default: false
    },
    selectedOptions: {
    },
    valueField: {
      default: "value"
    },
    placeholder: {
      default: "Select Tags"
    },
    isEditable: {
      default: false,
      type: Boolean
    },
    shouldCloseDropDown: {
      default: false
    }

  },
  data() {
    return {
      isOpen: false,
      isDarkMode: false,
      internalSelectedOptions: [],
      key: 0,
      searchText: '',
      internalIsEditable: this.isEditable,
      internalOptions: this.options,
      filteredOptions: this.options,

      testOptions: [
        {"heading": 'test1', "options": [1,2,3,4,5,6,7,8,9,]}
      ]
    }
  },
 mounted() {

   watch(() => this.options, (newOptions, oldOptions) => {
     this.internalOptions = newOptions
     this.filteredOptions = newOptions
   })

   watch(() => this.shouldCloseDropDown, (newValue, oldValue) => {
     this.$refs["icon"].classList.remove('active')
     this.$refs["myDropdown"].classList.remove("show");

   })

   this.selectedOptions.forEach(element => {
     this.addToSelected(element)
   })

   watch(() => this.selectedOptions, (selection, preSelection) => {
     this.internalSelectedOptions = []
       selection.forEach(element => {
         this.addToSelected(element)
       })
   })
  },

  computed: {
    ...mapState({
      isDarkmode: state => state.theme.isDarkmode,
    })
  },
  methods: {
    toggleDropdown() {
      console.log('toggle')
     this.$refs["myDropdown"].classList.toggle("show");
    },
    toggleSection(index) {
      let ref = "sectionContent" + index
      document.getElementById('arrow-icon' + index).classList.toggle('open')
      this.$refs[ref][0].classList.toggle("open")
    },

    addToSelected(value) {
        value['selected'] = true
        this.internalSelectedOptions.push(value)
        this.updateSelectedOptions(this.internalSelectedOptions)
    },
    searchEntry(text) {
      let searchText = text.toLowerCase()
      if (searchText === '') {
        this.filteredOptions = this.internalOptions
        return
      }
      if(this.isInSections) {
        this.filteredOptions = []
        for (let entry of this.internalOptions) {
          let filteredOptions = entry['options'].filter(option => {
            let value = option[this.valueField];
            let lowerCase = value.toLowerCase();
            return lowerCase.includes(searchText.toLowerCase());
          });
          this.filteredOptions.push({'heading': entry['heading'], 'options': filteredOptions});

          // Füge die gefilterten Optionen zum Ergebnis hinzu
        }
      }
      else {
        this.filteredOptions = this.internalOptions.filter(entry => {
          let value = entry[this.valueField]
          let lowerCase = value.toLowerCase()
          return lowerCase.includes(searchText)
        })
      }
    },
    removeFromSelected(value) {
      if(this.isEditable) {
        value['selected'] = false
        try {
          document.getElementById('checkbox' + value.id).checked = false
        } catch (e) {
          console.log(e)
        }
        this.internalSelectedOptions = this.deleteFromArray(this.internalSelectedOptions, value)
        this.updateSelectedOptions(this.internalSelectedOptions)
      } else {
     //   alert('This role is not editable!')
      }
    },
    deleteFromArray(array, toBeDeleted) {
      return array.filter(function (el) {
        return el.id !== toBeDeleted.id;
      })
    },
    updateSelectedOptions(options) {
      this.$emit('update:modelValue', options)
    },
    toggleIcon() {
      this.$refs["icon"].classList.toggle('active')
    },

    isItemSelected(id) {
        let result = false
        this.internalSelectedOptions.forEach(selected => {
          if (selected.id === id && selected.selected === true) {
            result = true
          }
        })
        return result
    },
  },



}
</script>

<style scoped>
@import "/src/styles/FormStyle.css";
@import "/src/styles/iconStyle.css";


#search-wrapper {
  width: auto;
  min-width: 120px;
  height: 35px;
  margin: 10px 10px 15px;
  border-radius: 5px;
  background-color: #eaeaea;
  display: flex;
}

#search-wrapper.darkmode {
  background-color: rgba(86,86,93,1);
}
#search-wrapper.lightmode {
  background-color: rgb(220, 220, 220);
}


#input {
  width: 100%;
  height: 40px;
  border-radius: 15px;
  border: none;
  background: none;
  color: var(--text-color-main);
}

textarea:focus, input:focus{
  outline: none;
}

select::-ms-expand {
  display: none;
}

#search-icon {
  display: flex;
  align-items: center;
  margin-left: 10px;
  width: fit-content;
}

.dropdown {
  position: relative;
  display: flex;
  justify-content: space-between;
}

#myDropdown {}

.content {
  display: block;
  position: absolute;
  /*max-width: 170px;*/

  min-width: 170px;
  height: 0px;
  overflow: hidden;
  margin-top: 45px;
  z-index: 1;
  transition: height 0.5s ease-in-out;
  border-radius: 5px;
  background-color: var(--input-element-background-primary);
}

#values {
  margin-left: 1px;
  max-width: 88%;
  overflow: scroll;
  height: 100%;
  white-space: nowrap;
  margin-bottom: 50px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-right: 10px;
  padding-left: 5px;
  border-radius: 15px 0px 0 15px;
}

.show {
  height: auto;

  /*  max-height: 350px;
  overflow: scroll;
 Firefox */
}

.material-symbols-outlined {
  display: block;
  cursor: pointer;
  margin-top: 8px;
  margin-right: 10px;
  height: fit-content;
  font-size: 22px;
  font-variation-settings:
      'FILL' 0,
      'wght' 200,
      'GRAD' 0,
      'opsz' 30

}

.icon {
  transition: transform 0.1s ease-in-out;
  margin-right: 4px;
}

.icon.active {
  transform: rotate(180deg);
}


#value{
  height: fit-content;
  width: 100%;
  display: flex;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  justify-content: flex-start;
  color: var(--input-text-lightmode);
  margin-top: 8px;
  margin-bottom: 8px;
}

.checkbox {
  width: 18px;
  height: 18px;
}

.checkbox:checked {
}

label {
  width: calc( 100% - 10px - 15px - 10px - 10px);
  font-size: 16px;
  margin-left: 5px;
  text-align: start;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.locked {
  cursor: not-allowed;
}

#section {
  margin-bottom: 10px;
}

.section-header {
  display: flex;
  align-items: center;
  margin-left: 10px;
}

.arrow-icon {
  font-size: 18px;
  margin: 0 5px 0 0px;
  transition: transform 0.1s ease-in-out;
  cursor: pointer;
}

.arrow-icon.invisible {
  opacity: 0;
  cursor: default;
}

.arrow-icon.open {
  transform: rotate(180deg);
}

.section-content {
  max-height: 0;
  overflow: hidden;
}

.section-content.open {
  max-height: fit-content;
  height: fit-content;
}

.invisible {
  opacity: 0;
}

@media (min-width: 400px) {
  .content {
    width: fit-content;
    max-width: available;
  }
  label {
    width: fit-content;
    max-width: 100%;
  }

}

</style>