<template>
  <div>
    <form id="login-form">
      <div id="login-top-bar">
        <h1 class="text biggest darkmode font-weight-normal">Welcome back!</h1>
      </div>
      <div id="login-sub-top-bar">
        <h1 class="text big darkmode font-weight-normal">Sign in</h1>
      </div>
      <div id="login-content">
        <div class="login-row">
          <h3 class="text default darkmode font-weight-normal">Email</h3>
          <input type="email" class="form-input" v-model="email" placeholder="Your email...">
        </div>
        <div class="login-row">
          <h3 class="text default darkmode font-weight-normal">Password</h3>
          <input type="Password" class="form-input" v-model="password" placeholder="Your password...">
        </div>
        <button class="auth-submit-button" @click.prevent="clickSignIn">Submit</button>
        <p class="text small darkmode" id="button-devider">Or</p>
        <button class="auth-signup-button">
          <router-link to="/sign-up">
            Create a new account
          </router-link>
        </button>
        <p class="text small darkmode" id="button-devider">Or</p>
        <button class="auth-signup-button">
          <router-link to="/forgot-pw">
            I forgot my password
          </router-link>
        </button>
      </div>

    </form>
  </div>
</template>

<script>
import {signIn} from "@/Scripts/API/AuthAPI";
import {routeTo} from "@/Router";
import {mapState} from 'vuex';
import EventBus from "@/Events/EventBus";

export default {
  name: "loginForm",
  data() {
    return {
      email: '',
      password: ''
    }
  },
  computed: {
    ...mapState({
      objectives: state => state.objectives.objectives
    }),
  },
  mounted() {
      document.title = "Log in - OKR System"
  },
  methods: {
    clickSignIn() {
      signIn(this.password, this.email, token => {
        this.$cookies.set('authToken', token, 166400)
        routeTo('Application')
        // todo: route to objectives
      })
    }
  }
}
</script>

<style scoped>
@import "@/styles/TextStyle.css";
@import "@/styles/PagesStyles/loginStyle.css";

a {
  color: var(--highlight-color-primary) !important;
}
</style>