<template>
  <form-background>
    <template #form>
      <form-wrapper :amount-content-screens="1" :button-options="buttonOptions" :heading="heading" @clickSubmit="submitAction" @delete="checkLeaveStudio" @closeForm="$emit('closeForm')">
        <template #contentBox0>
          <form-row :title="'Title'" :type="'text'" :preselected-value="title" :place-holder="'Studio name...'" :has-focus="true" v-model="title"/>
          <form-row :title="'Image'" :type="'image'" v-model="internalImage"/>
        </template>
      </form-wrapper>
    </template>
  </form-background>
  <insurance-component v-if="showForm" :text="insuranceText" @closeForm="toggleShowForm" @submit="leaveStudio"></insurance-component>
</template>

<script>
import {mapGetters, mapState} from "vuex";
import {convertImage} from "@/Utils";
import {clientEvents} from "@/Events/clientEventEnums";
import EventBus from "@/Events/EventBus";
import FormBackground from "@/components/formComponents/formBackground";
import FormWrapper from "@/components/formComponents/formWrapper";
import FormRow from "@/components/formComponents/formRow";
import {
  performRequestWithQuery,
  performPostRequest
} from "@/Scripts/API/Services/APIService";
import APIEndpoints from "@/Scripts/API/Services/APIEndpoints";
import InsuranceComponent from "@/components/miniComponents/InsuranceComponent";
import {formMixin} from "@/mixins/formMixin";
import {alertTypes} from "@/Enums";

export default {
  name: "studioForm",
  components: {InsuranceComponent, FormRow, FormWrapper, FormBackground},
  emits: ['closeForm', 'clickSubmit', 'updateStudio', 'addStudio', 'leaveStudio'],
  computed: {
    ...mapState({
      isDarkmode: state => state.theme.isDarkmode,
      ownUserData: state => state.user.user,
      studio: state => state.studio.studio,
      tags: state => state.tags.tags,
      users: state => state.users.users
    }),
    ...mapGetters('user', ['getUser', 'getUserRole', 'getUserId'])
  },
  mixins: [formMixin],
  props: {
    objective: {
      required: false
    },
    studio: {}

  },
  mounted() {
    if (this.studio !== null) {
      this.title = this.studio['name']
      this.heading = `Edit ${this.title}`
      this.buttonOptions.hasDelete = true
    }
  },
  data() {
    return {
      heading: 'New Studio',
      title: '',
      internalImageName: null,
      internalImage: null,
      buttonOptions: {
        'deleteTitle': 'Leave studio',
        'hasDelete': false,
        'hasSubmit': true
      },
      insuranceText: "This studio will be deleted if you leave. Confirm deletion?\n",
      showInsurance: false
    }
  },

  methods: {
    onInputChange(e) {
      const files = e.target.files || e.dataTransfer.files;
      if (!files) return;
      let image = files[0];
      this.internalImageName = image.name
      this.internalImage = image
    },
    removePreview() {
      this.internalImage = null
      this.internalImageName = ''
    },
    closeForm(event) {
      const validClasses = ['form-background', 'close-icon'];
      if (validClasses.some(className => event.target.classList.contains(className))) {
       this.$emit('closeForm')
      }
    },
    closeDropDowns(event) {
      const validClasses = ['form-card', 'form-container-top', 'form-container-content', 'form-container-bottom', 'form-content-row'];
      if (validClasses.some(className => event.target.classList.contains(className))) {
        this.shouldCloseDropDowns = !this.shouldCloseDropDowns
      }
    },
    async submitAction() {
      if (this.internalImage == null && this.studio === null) {
        EventBus.emit(clientEvents.ALERT_EVENT, 'Please select an image for your studio!', alertTypes.INFORMATION)
      } else if(this.title === '') {
        EventBus.emit(clientEvents.ALERT_EVENT, 'Please select title for your studio!', alertTypes.INFORMATION)
      }
      else {
        let formData = convertImage(this.internalImage)
        formData.append('title', this.title)

        if (this.studio === null) {
          console.log('should create studio')
          await performPostRequest(APIEndpoints.createStudio, formData).then(response => {
            this.$emit('addStudio', response)
            this.$emit('closeForm');
          })
        } else {
          formData.append('id', this.studio['id'])
          await performPostRequest(APIEndpoints.editStudio, formData).then(response => {
            this.$emit('updateStudio', response)
            this.$emit('closeForm');
          })
        }
      }
    },
    leaveStudio() {
      this.$emit('leaveStudio', this.studio.id)
      this.$emit('closeForm')
    },

    checkLeaveStudio() {
      performRequestWithQuery(APIEndpoints.checkCanLeave, this.studio.id).then(response => {
        const canLeave = response['can_leave_directly']
        if (canLeave) {
          this.leaveStudio()
        } else {
            this.toggleShowForm()
        }
      })
    }



  }
}
</script>

<style scoped>
@import "/src/styles/FormStyle.css";

form {
  height: 400px;
  width: 20%;
  min-width: 300px;
  max-width: 300px;
  display: flex;
  flex-direction: column;
}




input {
  border: none;
  border-radius: 5px;
  height: 25px;
}

label {
  white-space: nowrap;
}

.form-container-bottom {
  display: flex;
  justify-content: space-between;
}


/*
#heading {
  margin-right: auto;
  position: absolute;
  margin-left: 10px;
}*/

textarea {
  max-width:165px;
  min-width: 165px;
  border: none;
  border-radius: 5px;
  padding-right: 5px;
  padding-top: 5px;
  align-self: center;
  min-height: 50px;
  max-height: 50px;
  font-family: Arial;
  margin-top: 15px;
  display: flex;
}

/* Verstecke die Pfeiltasten */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

#prompt-information {
  max-width: 100%;
  min-width: 100%;

  text-align: start;
  height: fit-content;
  margin-top: 15px;
  margin-bottom: 15px;
  display: block; /* or inline-block, at least its a block element */
  width: 100px; /* or width is certain by parent element */
  height: auto; /* height cannot be defined */
  word-break: normal; /*  */
  word-wrap: break-word; /* if you want to cut the complete word */
  white-space: normal; /* be sure its not 'nowrap'! ! ! :/ */
}

#prompt-field {
  min-height: 150px;
  margin-top: 125px;
}

#area {
  height: 100px;
  border: 3px dashed var(--highlight-color-primary);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 64px 64px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  filter: alpha(opacity=50);
  -khtml-opacity: 0.5;
  -moz-opacity: 0.5;
  opacity: 0.5;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

#area:hover,
#area.dragging,
#area.uploading {
  color: var(--highlight-color-primary);
  /* filter: alpha(opacity=100);
   -khtml-opacity: 1;
   -moz-opacity: 1;*/
  opacity: 1;
}

#area input {
  width: 100%;
  height: 100%;
  border: none;
  cursor: pointer;
  opacity: 0;
  z-index: 1;
  position: relative;
}

#area #preview-image {
  width: 100%;
  height: 100%;
  z-index: 3;
}

#image-preview {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex: 1;
  max-width: 100%;
}

#upload-icon {
  color: var(--highlight-color-primary);
  opacity: 1;
  position: absolute;
  z-index: 10;
  cursor: pointer;
}

#area input:focus {
  outline: none;
}

#remove-selection-button {
  color: var(--highlight-color-cancel);
  font-size: 20px;
  cursor: pointer;
}

#preview-wrapper {
  height: 40px;
  max-width: 100%;
  flex: 1;
  overflow: hidden;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
</style>