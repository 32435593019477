<template>
  <div class="main-component-wrapper">
  <div class="page-heading-size top-bar">
      <h1 class="text page-heading font-weight-bold page-heading-size">
        <slot></slot>
      </h1>
      <slot name="page-edit-button"></slot>
      <div id="loading-wrapper" v-if="!isCustomer">
        <circle-spinner-small/>
      </div>
      <slot id="top-bar-content" name="top-bar-content">
      </slot>

    <div class="notification-wrapper">
      <div class="new-notification-wrapper" v-if="newNotifications > 0">
          <p class="text-heading smaller" id="notification-amount">{{ newNotifications }}</p>
      </div>
      <span class="material-symbols-outlined notification-icon fill" @click="toggleNotifications">notifications</span>
      <notifications-component v-if="showNotifications"/>

    </div>

    <div class="profile-picture-wrapper">
      <img  :src="baseMediaUrl + this.ownUser['image_url']" class="profile-picture">
      <profile-quick-menu v-if="showQuickMenu"/>
    </div>
    <!--<img  :src="baseMediaUrl + this.ownUser['image_url']" id="profile-picture" @click="routeTo('Profile')">-->
    </div>
    <slot id="top-bar-sub-content" name="top-bar-sub-content"></slot>
  <slot name="content"></slot>
  </div>
  <loading-component v-if="isLoading"/>
  <!--<notifications-component ref="notifications"/>-->
</template>


<script>
import {mapState} from 'vuex';
import {baseMediaUrl} from "@/Utils";
import {routeToProfile} from "@/Router";
import LoadingComponent from "@/components/miniComponents/UIComponents/LoadingComponent";
import CircleSpinnerSmall from "@/components/miniComponents/UIComponents/Loading/Spinners/CircleSpinnerSmall";
import {routeTo} from "@/Router";
import EventBus from "@/Events/EventBus";
import {clientEvents} from "@/Events/clientEventEnums";
import {notificationsMixin} from "@/mixins/notificationsMixin";
import ProfileQuickMenu from "@/components/profileQuickMenu";
import AddProfilePictureForm from "@/components/profile/forms/addProfilePictureForm";
import FormRow from "@/components/formComponents/formRow";
import InfoButton from "@/components/miniComponents/UIComponents/infoButton";
import NotificationsComponent from "@/components/SideBar/Notifictions/NotificationsComponent";
export default {
  name: "MainComponentWrapper",
  components: {NotificationsComponent, ProfileQuickMenu, LoadingComponent, CircleSpinnerSmall},
  mixins: [notificationsMixin],
  data() {
    return {
      imageUrl: '',
      routeToProfile,
      isNotificationsOpen: false,
      baseMediaUrl,
      routeTo,
      showQuickMenu: false,
      showNotifications: false
    }
  },
  computed: {
    ...mapState({
      isCustomer: state => state.user.isCustomer,
      ownUser: state => state.user.user,
      studio: state => state.studio.studio,
      isLoading: state => state.studio.isLoading,
    }),
  },
  mounted() {
    document.addEventListener('click', this.closeQuickMenu)
  },
  methods: {
    toggleNotifications() {
      this.showNotifications = !this.showNotifications
    },
    toggleShowQuickMenu() {
      this.showQuickMenu = !this.showQuickMenu
      console.log('showing quick menu: ', this.showQuickMenu)
    },
    closeQuickMenu(event) {
      const clickedElementClasses = Array.from(event.target.classList)
      console.log(clickedElementClasses)
      const forbiddenClasses = ['profile-quick-menu', 'quick-menu-list', 'list-row', 'list-item', 'img', 'button', 'close-icon', 'form-content', 'form-row', 'text', 'material-symbols-outlined', 'input']
      const profilePictureClasses = ['profile-picture-wrapper', 'profile-picture', 'close-icon']

      const hasForbiddenClass = clickedElementClasses.some((item) => forbiddenClasses.includes(item));
      const hasProfilePictureClass = clickedElementClasses.some((item) => profilePictureClasses.includes(item));

      if (hasForbiddenClass) {
        // Wenn eine verbotene Klasse gefunden wird, tun wir nichts
        return;
      }

      if (hasProfilePictureClass) {
        // Wenn es eine Klasse für das Profilbild gibt, wird das Menü umgeschaltet
        this.toggleShowQuickMenu();
      } else if (this.showQuickMenu) {
        // Wenn das Menü bereits angezeigt wird und es keine verbotene Klasse gibt, wird es geschlossen
        this.toggleShowQuickMenu();
      }
    }
  }
}
</script>

<style scoped>
@import "@/styles/PagesStyles/MainPageStyle.css";




</style>

