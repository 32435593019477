import API from "@/Scripts/API/Services/APIService";

export default {

    signIn(password, email) {
        return API().post('/authentication/login', {
            "password": password,
            "email": email
        })
    },

    signUp(data) {
        return API().post('/authentication/sign-up', {
            data
        })
    },

    getVerificationEmail(token) {
        return API(token).get('/authentication/send-verification-mail')
    },


    signOut(token) {
        return API(token).post('/authentication/token/logout')
    },

    checkLoginPermission(email) {
        return API().get('/authentication/check-login/user/' + email)
    },

    getFeedBackTypes(token) {
        return API(token).get('/feedback/feedback-types')
    },

    getFeedBacks(token) {
        return API(token).get(`/feedback/feedbacks?user=self`)
    },

    deleteFeedback(token, feedbackId) {
        return API(token).delete(`/feedback/feedbacks/delete/${feedbackId}`)
    },

    submitFeedback(token,data) {
        return API(token).post('/feedback/submit-feedback', data)
    },

    getOwnUserdata(token) {
        return API(token).get('/users/me')
    },

    getOwnUserStudioInvites(token) {
        return API(token).get('/users/me/get-studio-invites')
    },
    getOwnUserStudios(token) {
        return API(token).get('/users/me/get-studios')
    },

    signIntoStudio(token, data) {
        return API(token).put('/studios/studio/sign-in', {
            data
        })
    },

    denyInvite(token, invite_id, studio_id) {
        return API(token).post('/studios/studio/invites/deny-invite', {
            'invite_id': invite_id,
            'studio_id': studio_id
        })
    },

    acceptInvite(token, invite_id) {
        return API(token).post('/studios/studio/invites/accept-invite', {
            'invite_id': invite_id,
        })
    },

    changeSettingsData(token, data) {
        return API(token).put('/users/me/change-settings-data', data)
    },

    forgotPassword(email) {
        return API().get('/authentication/forgot-pw-request?email='+email)
    },

    getScreenVisits(token) {
        return API(token).get('/studios/studio/users/user/dashboard/screen-visits')
    },

    getNotificationAmount(token) {
        return API(token).get('/studios/studio/users/user/dashboard/notifications/amount')
    },

    getNotifications(token) {
        return API(token).get('/studios/studio/users/user/dashboard/notifications')
    },

    checkCanLeave(token, studioId) {
        return API(token).get('/studios/studio/check-can-leave?studio_id='+studioId)
    },
    leaveStudio(token, studioId) {
        return API(token).delete('/studios/studio/leave?studio_id='+studioId)
    },

    getNotificationsForDate(token, date) {
        return API(token).get('/studios/studio/users/user/dashboard/notifications?date='+date)
    },

    setScreenVisits(token, data) {
        return API(token).put('/studios/studio/users/user/dashboard/screen-visits', data)
    },

    createStudio(token, formData) {
        console.log(formData)
        return API(token).post('/studios/', formData, {
            headers: {
                'Authorization': `Token ${token}`,
                'Content-Type': 'multipart/form-data',
                'Access-Control-Allow-Origin': true,
            }
        })
    },

    editStudio(token, formData) {
        return API(token).put('/studios/', formData, {
            headers: {
                'Authorization': `Token ${token}`,
                'Content-Type': 'multipart/form-data',
                'Access-Control-Allow-Origin': true,
            }
        })
    },

    deleteNotifications(token, data) {
        return API(token).delete('/studios/studio/users/user/notifications/delete?notification=' + data)
    },

    uploadProfilePicture(token, formData) {
        return API(token).post('/studios/studio/users/user/me/upload-profile-picture/', formData, {
            headers: {
                'Authorization': `Token ${token}`,
                'Content-Type': 'multipart/form-data',
                'Access-Control-Allow-Origin': true,
            }
        })
    },
}