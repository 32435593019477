<template>
  <settings-page-module>
    <form-row :title="'Theme'" :type="'custom'">
      <template #customRowInput>
      <select class="input-element input" @input="setTheme" :value="currentTheme">
          <option :value="themes.DARK_MODE.value">{{themes.DARK_MODE.visibleName}}</option>
          <option :value="themes.LIGHT_MODE.value">{{themes.LIGHT_MODE.visibleName}}</option>
          <option :value="themes.SYSTEM.value">{{themes.SYSTEM.visibleName}}</option>
        </select>
      </template>
    </form-row>
  </settings-page-module>
</template>

<script>
import SettingsPageModule from "@/components/Settings/modules/settingsPageModule";
import {themeMixin} from "@/mixins/themeMixin";
import {themes} from "@/Enums";
import FormRow from "@/components/formComponents/formRow";

export default {
  name: "themeModule",
  components: {FormRow, SettingsPageModule},
  mixins: [themeMixin],
  data() {
    return {
      themes,
      currentTheme: 'dark'
    }
  },
  mounted() {
    this.currentTheme = this.getTheme()
  },
  methods: {
    setTheme(element) {
      let value = element.target.value
      this.saveTheme(value)
    },
    getTheme() {
      return localStorage.getItem('theme')
    }
  },

}
</script>

<style scoped>
@import "@/styles/PagesStyles/SettingsPage.css";
@import "@/styles/FormStyle.css";


</style>