<template>
  <div>
    <form id="login-form">
      <div id="login-top-bar">
        <h1 class="text biggest darkmode font-weight-normal">Sign up!</h1>
      </div>
      <div id="login-sub-top-bar">
        <h1 class="text big darkmode font-weight-normal">Create a new account</h1>
      </div>
      <div id="login-content">
        <div class="login-row">
          <h3 class="text default darkmode font-weight-normal">Username</h3>
          <input type="text" class="form-input" v-model="userName">
        </div>
        <div class="login-row">
          <h3 class="text default darkmode font-weight-normal">Email</h3>
          <input type="email" class="form-input" v-model="email">
        </div>
        <div class="login-row">
          <h3 class="text default darkmode font-weight-normal">Password</h3>
          <input type="Password" class="form-input" v-model="password">
        </div>
        <div class="login-row">
          <h3 class="text default darkmode font-weight-normal">Re Password</h3>
          <input type="Password" class="form-input" v-model="rePassword">
        </div>
        <button class="auth-submit-button" @click.prevent="clickSigUp">Submit</button>
        <p class="text small darkmode" id="button-devider">Or</p>
        <button class="auth-signup-button">
          <router-link to="/">
            Sign in
          </router-link>
        </button>
      </div>

    </form>
  </div>
</template>

<script>
import {signUpStudio} from "@/Scripts/API/StudioAPI";
import EventBus from "@/Events/EventBus";
import {clientEvents} from "@/Events/clientEventEnums";
import {signUp} from "@/Scripts/API/AuthAPI";
import {alertTypes} from "@/Enums";

export default {
  name: "signUpForm",
  data() {
    return {
      userName: '',
      studioName: '',
      email: '',
      password: '',
      rePassword: ''
    }
  },
  mounted() {
    document.title = "Sign up - OKR System"
  },
  methods: {
    clickSigUp() {
      console.log('test')
      if (this.password == this.rePassword) {
        const object = {
          "username": this.userName,
          "email": this.email,
          "password": this.password,
          "re_password": this.rePassword
        }
        signUp(object)
      } else {
        EventBus.emit(clientEvents.ALERT_EVENT,  'Password and Re-password need to match!', alertTypes.INFORMATION)
      }
    }
  }
}
</script>

<style scoped>
@import "@/styles/TextStyle.css";
@import "@/styles/PagesStyles/loginStyle.css";



</style>