import { createApp } from 'vue'
import router from "@/Router"
import App from './App.vue'
import store from "@/stores/Store"
import VueCookies from "vue-cookies"



createApp(App)
    .use(router)
    .use(store)
    .use(VueCookies).mount('#app');
   // .provide('store', store)
