import { mapState, mapMutations, mapGetters } from 'vuex';

export const studioMixin = {
    computed: {
        ...mapState({
            studio: state => state.studio.studio,
        }),
        ...mapGetters('studio', ['getConnection'])
    },
    methods: {
        ...mapMutations('studio', ['setIsLoading', 'setIsLoadingSmall'])
    }
};