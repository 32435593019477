import {baseApiUrl, baseWsUrl} from "@/Utils";
import store from "@/stores/Store";
export async function openSocketConnection(token, callBack) {
    console.log('open socket conneczion')
    //const connection = new WebSocket(`ws://81.169.220.49:8000/ws/${token}/studio/${studioID}/`)
  const connection = new WebSocket(`${baseWsUrl}/auth-token/${token}`)

    callBack(connection)
}

export async function sendSocketMessage(connection, data) {
    connection.send(JSON.stringify(data))
}

export async function receiveSocketMessage(connection, callBack) {
    connection.onmessage = (e) => {
        callBack(e)
    }
}

export async function onSocketClose(connection, callBack) {
    connection.onclose = (e) => {
        callBack(e.data)
    }
}

export async function onSocketError(connection, callBack) {
    connection.onError = (e) => {
        callBack(e.data)
    }
}