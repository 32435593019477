<template>
  <settings-page-module>
    <template #default>
      <div class="wrapper">
        <form-row :title="'Email'" :preselected-value="email" v-model="email" :is-disabled="true" :info-button-options="infoButtonOptionsEmail" :has-info-button="!user['is_verified']" :type="'email'"/>
        <form-row :title="'Password'" :preselected-value="password" v-model="password" :info-button-options="infoButtonOptionsPassword" :has-info-button="true" :type="'password'"/>
        <form-row :title="'Emails'" :preselected-value="shouldReceiveEmail" v-model="shouldReceiveEmail" :info-button-options="infoButtonOptionsGetEmail" :has-info-button="true" :type="'checkbox'"/>

      </div>
      <div id="spinner-container">
        <circle-spinner-small-c-p v-bind="spinner" :id="'credentials-spinner'" ref="credentials-spinner"></circle-spinner-small-c-p>
      </div>
  <button id="submit-button" @click.prevent="clickSubmit">Submit</button>
    </template>

  </settings-page-module>
</template>

<script>
import SettingsPageModule from "@/components/Settings/modules/settingsPageModule";
import {themeMixin} from "@/mixins/themeMixin";
import {alertTypes, themes} from "@/Enums";
import {userMixin} from "@/mixins/userMixin";
import EventBus from "@/Events/EventBus";
import {clientEvents} from "@/Events/clientEventEnums";
import CircleSpinnerSmallCP from "@/components/miniComponents/UIComponents/Loading/Spinners/CircleSpinnerSmallCP";
import InfoButton from "@/components/miniComponents/UIComponents/infoButton";
import {performRequest, performPostRequest} from "@/Scripts/API/Services/APIService";
import APIEndpoints from "@/Scripts/API/Services/APIEndpoints";
import FormRow from "@/components/formComponents/formRow";


export default {
  name: "credentialsModule",
  components: {FormRow, CircleSpinnerSmallCP, SettingsPageModule},
  mixins: [themeMixin, userMixin],
  data() {
    return {
      themes,
      currentTheme: 'dark',
      email: '',
      password: '',
      spinner: null,
      shouldReceiveEmail: true,
      infoButtonOptionsEmail: {
          texts: ['Your email address is not verified.', ' Please check your emails and spam folder for the verification email!', 'You can also press the button below to resend the email.'],
          isWarning: true,
          hasButton: true,
          buttonText: 'Resend email',
          buttonAction: this.resendVerificationEmail
        },
      infoButtonOptionsPassword: {
        texts: ['Leave this field empty if you do not want change your password.'],
        isWarning: false,
        hasButton: false,
      },
      infoButtonOptionsGetEmail: {
        texts: ['Check this if you want to receive an email when you get a new notification.', "Uncheck it if you don't want to receive an email."],
        isWarning: false,
        hasButton: false,
      }
    }
  },
  beforeMount() {
    this.email = this.user.email
    console.log(this.user)
  },
  mounted() {
    this.shouldReceiveEmail = this.user["should_get_notification_email"]
  },
  methods: {
  clickSubmit() {
    if (this.email === '') {
      EventBus.emit(clientEvents.ALERT_EVENT, 'The email field must not be empty!')
    }
    this.$refs['credentials-spinner'].startAnimation()


    performPostRequest(APIEndpoints.changeSettingsData, {"password": this.password, "receive_notification_emails": this.shouldReceiveEmail}).then(() => {
      this.$refs['credentials-spinner'].endAnimation()
      this.user["should_get_notification_email"] = this.shouldReceiveEmail
      EventBus.emit(clientEvents.ALERT_EVENT, 'Data changed!', alertTypes.INFORMATION)
    })
  },
    resendVerificationEmail() {
    // api Aufruf
      // Pop message
      performRequest(APIEndpoints.getVerificationEmail).then(() => {
        EventBus.emit(clientEvents.ALERT_EVENT, 'We send you a new verification email! Please check your emails folder.', alertTypes.INFORMATION)
      })
    }
  },

}
</script>

<style scoped>
@import "@/styles/PagesStyles/SettingsPage.css";
@import "@/styles/FormStyle.css";

.settings-content-row {
  height: fit-content;
}

.input-element {
  width: 100%;
  height: 40px;
  font-size: 16px;
}

label {
  width: fit-content;
  min-width: 145px;
  max-width: 145px;
}

.wrapper {
  display: flex;
  flex-direction: column;
  row-gap: 15px;
}


#submit-button {
  position: absolute;
  bottom: 20px;
  right: 40px;
  background-color: var(--highlight-color-primary);
  color: #eaeaea;
}

#spinner-container {
  position: absolute;
  bottom: 0;
  left: 0;
}

.info-button {
  cursor: pointer;
}

input[type="email"] {
  opacity: .5;
  cursor: not-allowed;
}

</style>