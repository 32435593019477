<template>
  <form :style="externalFormStyles" class="form-card">
    <div class="form-container-top medium">
      <span class="text font-weight-normal">{{heading}}</span>
      <span class="material-symbols-outlined close-icon" @click="$emit('closeForm')">close</span>
    </div>
    <slot name="customContent"></slot>
    <div class="form-content" :class="{hidden: index !== currentIndex}" v-for="index in [...Array(amountContentScreens).keys()]" :key="index">
      <slot :name="`contentBox${index}`"></slot>
    </div>
    <div class="buttons-wrapper" v-if="internalButtonOptions.hasSubmit || internalButtonOptions.hasDelete">
      <button class="button left-button text default highlighted" v-if="amountContentScreens > 1 && currentIndex > 0" @click.prevent="changeCurrentIndex(-1)">Previous</button>
      <button class="button left-button text default highlighted-red" v-if="(amountContentScreens === 1 || currentIndex === 0) && internalButtonOptions.hasDelete" @click.prevent="setShowInsuranceWrapper(true)">{{buttonOptions['deleteTitle']}}</button>

      <button class="button right-button text default highlighted" v-if="amountContentScreens > 1 && currentIndex < amountContentScreens - 1" @click.prevent="changeCurrentIndex(+1)">Next</button>
      <button class="button right-button text default highlighted" v-if="(amountContentScreens === 1 || currentIndex === amountContentScreens - 1) && internalButtonOptions.hasSubmit"  @click.prevent="$emit('clickSubmit')">Submit</button>
    </div>
  </form>

  <insurance-component :external-style="'left: 0;'" v-if="showInsuranceWrapper" @closeForm="setShowInsuranceWrapper(false)" @submit="$emit('delete'); setShowInsuranceWrapper(false)"/>
</template>

<script>
import InsuranceComponent from "@/components/miniComponents/InsuranceComponent";
import EventBus from "@/Events/EventBus";
import {clientEvents} from "@/Events/clientEventEnums";
export default {
  name: "formWrapper",
  components: {InsuranceComponent},
  emits:['delete', 'clickSubmit', 'closeForm'],
  props: {
    heading: {},
    amountContentScreens: {
      default: 2,
      type: Number
    },
    buttonOptions: {},
    externalFormStyles: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      currentIndex: 0,
      showInsuranceWrapper: false,
      slots: ['content1', 'content2', 'content3', 'content4', 'content5', 'content6'],
      internalButtonOptions: {
        'hasSubmit': true,
        'hasDelete': true
      }
    }
  },
  mounted() {
    if (this.buttonOptions !== null) {
      this.internalButtonOptions = this.buttonOptions
    }
    EventBus.on(clientEvents.CLICKED_FORM_BACKGROUND, () => {
      this.$emit('closeForm')
    })
    document.addEventListener("keydown", this.keyDownEnter);
  },
  beforeUnmount() {
    // Entferne den Event-Listener, wenn die Komponente zerstört wird
    document.removeEventListener("keydown", this.keyDownEnter);
  },
  watch: {
    buttonOptions(newOptions) {
      this.internalButtonOptions = newOptions
    }
  },
  methods: {
    changeCurrentIndex(value) {
      this.currentIndex += value
    },
    setShowInsuranceWrapper(bool) {
      this.showInsuranceWrapper = bool
    },
    keyDownEnter(event) {
      if (event.key === "Enter") {
        const elements = document.querySelectorAll('textarea');  // Nur submitten, wenn keine textarea fokussiert ist!
        const elementsArray = Array.from(elements);
        console.log(elementsArray)
        const hasFocus = elementsArray.some((element) => element === document.activeElement);
        if (!hasFocus) this.$emit('clickSubmit')
      }
    }
  }
}
</script>

<style scoped>
@import "@/styles/FormStyle.css";




</style>