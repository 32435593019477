<template>
  <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200" />
  <link href="https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,400&display=swap" rel="stylesheet">
    <section id="app-content">
      <router-view></router-view>
    </section>
</template>

<script>

import { mapMutations, mapState,} from 'vuex';

export default {
  name: 'App',
  data() {
    return {
      connection: WebSocket,
      internalUsers: [],
      cookie: this.$cookies.get('authToken'),
    }
  },

  computed: {
    ...mapState({
      tags: state => state.tags.tags,
      objectives: state => state.objectives.objectives,
      isDarkmode: state => state.theme.isDarkmode,
      studio: state => state.studio.studio,
      roles: state => state.roles.roles,
      ownUser: state => state.user.user,
      isCustomer: state => state.user.isCustomer,
      users: state => state.users.users,
      lastScreenVisit: state => state.user.visitedScreensLast,
      newNotifications: state => state.notifications.newNotifications
    }),
  },
  mounted() {
  },
  methods: {
    ...mapMutations('theme', ['setDarkmode']),
  },
}
</script>

<style>
@import "@/styles/generalStyle.css";

body {
margin: 0px 0px 0px 0px;
padding: 0px 0px 0px 0px;
width: 100vw;
height: 100vh;
-ms-overflow-style: none;  /* IE and Edge */
scrollbar-width: none;  /* Firefox */
}

#app {
font-family: 'Rubik', sans-serif;

display: flex;
flex-direction: column;
align-items: center;
width: 100vw;
height: 100vh;

}

#app-content {
/* width: 100%;
min-height: calc(100vh - 100px);
margin-top: 100px; */
width: 100vw;
height: 100vh;
position: fixed;
overflow: hidden;
}

::-webkit-scrollbar {
display: none;
}

#app-content {
  background-color: var(--app-background-color-main);
}
</style>
