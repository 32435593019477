<template>
<form-background :external-style="externalStyle">
  <template #form>
    <div class="form-card">
      <div class="form-container-top">
        <p class="text medium font-weight-normal">{{internalText}}</p>
        <span class="material-symbols-outlined close-icon" id="close-icon" @click="closeForm">close</span>
      </div>
      <div class="form-container-bottom">
        <button id="delete-button" class="delete-button text medium" @click="closeForm">No</button>
        <button id="submit-button" class="delete-button text medium" @click="submit">Yes</button>
      </div>
    </div>
  </template>
</form-background>
</template>

<script>
import FormBackground from "@/components/formComponents/formBackground";
import EventBus from "@/Events/EventBus";
import {clientEvents} from "@/Events/clientEventEnums";
export default {
  name: "InsuranceComponent",
  components: {FormBackground},
  emits: ['closeForm', 'submit'],
  props: {
    externalStyle: {},
    text: {}
  },
  data() {
    return {
      internalText: "Are you sure?"
    }
  },
  mounted() {
    if (this.text) this.internalText = this.text
    EventBus.on(clientEvents.CLICKED_FORM_BACKGROUND, () => {
      this.$emit('closeForm')
    })
  },
  beforeMount() {
    if(!this.internalText) this.internalText = "Are you sure?"
    },
  methods: {
    closeForm(event) {
      const validClasses = ['form-background', 'close-icon', 'delete-button'];
      if (validClasses.some(className => event.target.classList.contains(className))) {
        this.$emit('closeForm')
      }
    },
    submit() {
      this.$emit('submit')
    }
  },
  watch: {
    text(newText) {
      this.internalText = newText
    }
  }
}
</script>

<style scoped>
  @import "@/styles/FormStyle.css";
  .form-card {
    z-index: 1000000001;
    position: absolute;
    margin: auto;
    min-width: 350px;
    padding-inline: 20px;
    padding-block: 10px;
    height: fit-content;
    background-color: var(--formcard-background-primary);
    display: flex;
    flex-direction: column;
    gap: 40px;
  }

  .form-container-bottom {
    width: auto;
    display: flex;
    justify-content: space-between;
    height: fit-content;
  }

  #delete-button, #submit-button {
    margin: 0;
    width: fit-content;
    padding: 0;
    height: fit-content;
  }
</style>