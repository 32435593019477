<template>
  <main-component-wrapper>
    Studios
   <template #content>
     <div id="content">
       <studios-option-bar @clickAdd="toggleShowForm" @searchStudios="searchStudios"/>
       <div id="studios-wrapper">
         <studio-list-module v-for="studio in filteredStudios" :key="studio.id" :studio="studio" @leaveStudio="leaveStudio"></studio-list-module>
       </div>
     </div>

    </template>
  </main-component-wrapper>
  <studio-form v-if="showForm" @closeForm="toggleShowForm" :studio="null"  @addStudio="addStudio"/>
</template>

<script>

import {mapState} from "vuex";
import MainComponentWrapper from "@/components/MainComponentWrapper";

import StudiosOptionBar from "@/components/studios/modules/studiosOptionBar";
import {addableContentMixin} from "@/mixins/addableContentMixin";
import {getOwnUserStudios} from "@/Scripts/API/UserAPI";
import StudioListModule from "@/components/studios/modules/studioListModule";
import StudioForm from "@/components/studios/forms/studioForm";
import {performRequestWithQuery, performPostRequest} from "@/Scripts/API/Services/APIService";
import APIEndpoints from "@/Scripts/API/Services/APIEndpoints";
import {userMixin} from "@/mixins/userMixin";
import EventBus from "@/Events/EventBus";
import {clientEvents} from "@/Events/clientEventEnums";

export default {
  name: "StudiosComponent",
  components: {StudioForm, StudioListModule, StudiosOptionBar, MainComponentWrapper},
  mixins: [addableContentMixin, userMixin],
  data() {
    return {
      allStudios: [],
      filteredStudios: []
    }
  },
  computed: {
    ...mapState({
    }),

  },
  methods: {
    addStudio(studio) {
      this.allStudios.push(studio)
      this.filteredStudios = this.allStudios
    },
    searchStudios(text) {
      if (text === '') {
        this.filteredStudios = this.allStudios
        return
      }

      this.filteredStudios = this.allStudios.filter(studio => studio.name.toLowerCase().includes(text.toLowerCase()))
    },
    leaveStudio(id) {
      performRequestWithQuery(APIEndpoints.leaveStudio, id).then(() => {
        this.allStudios = this.allStudios.filter(studio => studio.id !== id)
        this.filteredStudios = this.filteredStudios.filter(studio => studio.id !== id)

      })
    }
  },
  mounted() {
    EventBus.on(clientEvents.CLICKED_FORM_BACKGROUND, () => {
      this.showForm = false
    })
    getOwnUserStudios(this.$cookies.get('authToken'), studios => {
      this.allStudios = studios
      this.filteredStudios = studios
    })
    if(this.visitedScreens) {
      this.visitedScreens['studios_visited_last'] = new Date().toISOString()
      performPostRequest(APIEndpoints.setScreenVisits, this.visitedScreens)
    }
  },
}
</script>


<style scoped>

  #studios-wrapper {
    flex: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    overflow: auto;
  }

  #content {
    flex: 1;
    display: flex;
    row-gap: 20px;
    flex-direction: column;
  }






</style>