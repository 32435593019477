import { mapState, mapMutations } from 'vuex';

export const invitesMixin = {
    computed: {
        ...mapState({
            invites: state => state.invites.invites,
            newInvitesCount: state => state.invites.newInvitesCount
        }),
    },
    methods: {
        ...mapMutations('invites', ['setInvites', 'setNewInvitesCount', 'addInvite'])

    }
};